import React, { useRef, useState } from 'react';

import { Button, Input } from '@netfront/ui-library';

import { ESize, SearchFilterProps } from './SearchInput.types';

import { getValidClassNames } from '../../../utils';

const SearchInput = ({
  additionalClassNames,
  isDisabled,
  isSearchActive,
  onClear: onClearProp,
  onSearch: onSearchProp,
  size = ESize.sm,
  ...rest
}: SearchFilterProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);

  const handleSearchOnEnter = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'Enter') {
      event.preventDefault();
      onSearch();
    }
  };

  const onClear = () => {
    if (inputRef.current === null) return;
    inputRef.current.value = '';
    onClearProp();
  };

  const onSearch = () => {
    onSearchProp(String(inputRef.current?.value));
  };

  return (
    <form
      className={getValidClassNames({
        'c-search-input': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
        [`c-search-input--${String(size)}`]: Boolean(size),
      })}
    >
      <div className="c-search-input__input">
        <Input
          ref={inputRef}
          {...rest}
          isDisabled={isDisabled}
          onChange={({ target: { value: searchValue } }) => {
            if (isSearchActive && searchValue !== '') {
              onSearch();
            }

            if (isSearchActive && searchValue === '') {
              onClear();
            }

            setIsSearchDisabled(searchValue === '');
          }}
          onKeyPress={handleSearchOnEnter as () => void}
        />

        <Button
          additionalClassNames={getValidClassNames({
            'c-search-input__button': true,
            'c-search-input__button--close': Boolean(isSearchActive),
          })}
          iconId={isSearchActive ? 'id_close_icon' : 'id_search_icon'}
          isDisabled={isSearchDisabled}
          text={isSearchActive ? 'Clear search' : 'Search'}
          type="button"
          variant="cta"
          isIconOnly
          onClick={isSearchActive ? onClear : onSearch}
        />
      </div>
    </form>
  );
};

export { SearchInput };
