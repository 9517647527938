import { useEffect, useState } from 'react';

import { createCookie, setCookie, useCookie } from '@netfront/common-library';
import { IUserFlowStepTrack, useGetUserFlowStepTracksWithAnswers } from '@netfront/ekardo-content-library';
import { useDomain } from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';
import { DBCommunity } from 'interfaces';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useGetCommunitiesByUser } from 'services';

import { EmptyMessage, Feed } from 'components/Social';

import { incrementSelfAssessmentPrompt } from './Dashboard.constants';

import {
  Announce,
  AppStoreLinks,
  ArrowRightIcon,
  BookmarkCard,
  BreadcrumbLink,
  CalendarCard,
  CardTooltip,
  CheckinCard,
  CLUSTER_ID_ALCOHOL,
  CLUSTER_ID_K10,
  CurrentModuleCard,
  DashboardCard,
  ESelfAssessmentIndicatorTheme,
  EvaluationQuestionnairePrompt,
  GoalsCard,
  LatestSelfAssessmentResultCard,
  LatestSelfAssessmentResultCardEmpty,
  Page,
  QRAppleStore,
  QRGoogleStore,
  SPOTLIGHT_COOKIE,
  useGetCurrentModule,
  useGetSelfAssessmentResults,
} from '../../../components';
import { useGetRootBreadcrumbItems, useIsMounted, useProtectedRoute } from '../../../hooks';

const DashboardPage = () => {
  const PAGE_TITLE = 'Dashboard';
  const QR_COOKIE = 'shouldHideQRPrompt';
  
  const { asPath } = useRouter();
  const { getCookieValue } = useCookie();
  const { getDomain } = useDomain();
  const { isMounted } = useIsMounted()

  const { isAuthenticated } = useProtectedRoute();
  const { hasStartedUserflow, lastViewedStep } = useGetCurrentModule();
  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: false,
  });

  const [latestTracking, setLatestTracking] = useState<IUserFlowStepTrack | null>();
  const [isPromptingUserToCompleteSelfAssessment, setIsPromptingUserToCompleteSelfAssessment] = useState<boolean>(false);
  const [userCommunities, setUserCommunities] = useState<DBCommunity[]>([]);
  const [shouldShowQrBanner, setShouldShowQrBanner] = useState<boolean>(!getCookieValue(QR_COOKIE));

  const { getUserCommunities, isLoading: isLoadingUserCommunities } = useGetCommunitiesByUser({
    onCompleted: (connections) => setUserCommunities(connections.map(({ node }) => node)),
  });

  const isMoreThan = (dateToCompare: Date, months: number) => {
    // create a new Date object with the current date
    const currentDate: Date = new Date();

    // calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds: number = currentDate.getTime() - dateToCompare.getTime();

    // calculate the number of months between the two dates
    const differenceInMonths: number = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);

    // check if the difference is greater than 3 months
    return differenceInMonths > months;
  };

  const { handleGetUserFlowStepTracksWithAnswers } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: ({ userFlowStepTracks }) => {
      const lastTracking = [...userFlowStepTracks].find(({ isCompleted }) => isCompleted);
      if (!lastTracking || isMoreThan(new Date(lastTracking.completedLongDate), 3)) {
        if(getCookieValue(SPOTLIGHT_COOKIE) === 'true') return;

        const selfAssessmentPromptLocalStorage = Number(localStorage.getItem('selfAssessmentPrompt'));

        if(selfAssessmentPromptLocalStorage !== 3) {
          setIsPromptingUserToCompleteSelfAssessment(true);
        }
        incrementSelfAssessmentPrompt();
      }
      setLatestTracking(lastTracking);
    },
  });

  const { maxAssistItem, assistScoresExcludingSmoking, k10Score, mentalHealthRiskCategory, substanceRiskCategory } =
    useGetSelfAssessmentResults({
      trackingId: Number(latestTracking?.id),
    });


  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      userFlowStepUrls: ['self-assessment'],
      clusterIds: [CLUSTER_ID_K10, CLUSTER_ID_ALCOHOL],
    });

    getUserCommunities({variables: { shouldIncludeCommunities: true }});


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const hasCommunity = !isLoadingUserCommunities && userCommunities.length > 0;
  const firstCommunityId = hasCommunity ? userCommunities[0].id : undefined;

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{ seoDescription: 'View everything at a glance on your personalised dashboard', seoTitle: 'Dashboard | Stable Ground' }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <Announce location="dashboard" />
      <Dialog
        isOpen={isPromptingUserToCompleteSelfAssessment}
        title="Self assessment"
        onClose={() => {
          setIsPromptingUserToCompleteSelfAssessment(false);
        }}
      >
        <>
          <p>
            Start your self-assessment to receive feedback about your substance use and mental health, as well as personalised
            recommendations on what you could do next.
          </p>
          <Button 
            linkButton={{ id: 'self-assessment', url: '/self-assessment/introduction' }} 
            text='Click here to start' 
          />
        </>
      </Dialog>
      <div className="container container-md pb-12 spotlight-item--5">

        <EvaluationQuestionnairePrompt />

        <div className="flex flex-wrap -mx-4 mb-10">
          {isMounted && shouldShowQrBanner && (
            <div className='w-full px-4 mb-12'>
              <DashboardCard>
                <div className='flex flex-col md:flex-row items-center gap-6'>
                  <div className='w-full md:w-1/3'>
                    <h2 className='h3 color-secondary mb-1'>Get the app</h2>
                    <p className='m-0'>Download the free Stable Ground app, now available for Apple and Android devices.</p>
                  </div>

                  <div className='w-full md:w-1/3'>
                    <div className='flex justify-center items-center gap-6'>
                      <div className='flex flex-col items-center'>
                        <QRAppleStore />
                        <span className='mb-0'>Apple</span>
                      </div>
                      <div className='flex flex-col items-center'>
                        <QRGoogleStore />
                        <span className='mb-0'>Google</span>
                      </div>
                      <div>
                        <img alt="Arrow to QR codes" className='absolute -translate-1/2' src="/images/arrow.svg" />
                        <span className='text-lg line-1.4'>Scan to download</span>
                      </div>
                    </div>
                  </div>

                  <div className='w-full md:w-1/3'>
                    <AppStoreLinks />
                  </div>

                  <Button 
                    additionalClassNames='absolute p-0-5 right-4 top-4'
                    iconId='id_close_icon'
                    size='xs'
                    text='Close'
                    variant='tertiary'
                    isIconOnly
                    onClick={() => {
                      setCookie(createCookie(QR_COOKIE, 'true', {
                        domain: getDomain(),
                        storageExpiryOptions: {
                          currentTimeValueInMilliseconds: new Date().getTime(),
                          expiryTime: {
                            unit: 'hours',
                            value: 6,
                          },
                        },
                      }))

                      setShouldShowQrBanner(false);
                    }}
                  />
                </div>
              </DashboardCard>
            </div>
          )}

          <div className="w-full md:w-1/2 px-4 mb-12">
            {lastViewedStep && (
              <div className="flex flex-col h-full">
                <div className='flex items-center mb-3'>
                  <NextLink href="/program">
                    <a className="h6 color-secondary weight-800 flex items-center mr-3">
                    Steady program
                      <div className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center ml-2">
                        <ArrowRightIcon additionalClassNames="w-2 h-2" />
                      </div>
                    </a>
                  </NextLink>
                  <CardTooltip
                    text='A self-guided program with some helpful skills and strategies that can help you change your substance use.'
                  />
                </div>

                <CurrentModuleCard
                  description={String(lastViewedStep.description)}
                  hasStartedUserflow={hasStartedUserflow}
                  moduleId={lastViewedStep.id}
                  percentageCompleted={lastViewedStep.percentageCompleted}
                  status={lastViewedStep.stepName}
                  title={lastViewedStep.stepName}
                  url={
                    lastViewedStep.lastViewedContentPage
                      ? `/program/${lastViewedStep.url}/${String(lastViewedStep.lastViewedContentPage.url)}`
                      : `/program/${lastViewedStep.url}/${String(lastViewedStep.firstContentPage?.url)}`
                  }
                />

              </div>
            )}
          </div>

          <div className="w-full md:w-1/2 px-4 mb-12">
            <div className="flex flex-col h-full">
              <div className='flex items-center mb-3'>
                <NextLink href="/community">
                  <a className="h6 color-secondary weight-800 flex items-center mr-3">
                  Community
                    <div className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center ml-2">
                      <ArrowRightIcon additionalClassNames="w-2 h-2" />
                    </div>
                  </a>
                </NextLink>
                <CardTooltip 
                  text='The Community is a safe and anonymous space to share your experiences and thoughts with other members.'
                />
              </div>

              {(!isLoadingUserCommunities && hasCommunity) ? (
                 <Feed communityId={firstCommunityId} getLatestPosts={1} status="ACTIVE"  isPreviewMode />
              ) :
                <EmptyMessage message="No communities found" />}
            </div>
          </div>

          <div className="w-full md:w-1/2 px-4 mb-12">
            <CalendarCard />
          </div>

          <div className="w-full md:w-1/2 px-4 mb-16">
            <CheckinCard />
          </div>

          <div className="w-full px-4 mb-12">
            <div className='flex items-center mb-3'>
              <NextLink href="/self-assessment/introduction">
                <a className="h6 color-secondary weight-800 flex items-center mr-3">
                  Self assessment
                  <div className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center ml-2">
                    <ArrowRightIcon additionalClassNames="w-2 h-2" />
                  </div>
                </a>
              </NextLink>
              <CardTooltip
                text='A confidential self-assessment that asks about your recent substance use and mental health. '
              />
            </div>

            {latestTracking ? (
              <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-1/2 px-4">
                  <LatestSelfAssessmentResultCard
                    date={latestTracking.completedLongDate}
                    indicator={{
                      isLoading: Object.values(assistScoresExcludingSmoking).includes(null),
                      max: 39,
                      values: [{ label: maxAssistItem.item, value: maxAssistItem.score }],
                    }}
                    subTitle="Your substance use is at"
                    title={substanceRiskCategory}
                  />
                </div>
                <div className="w-full md:w-1/2 px-4">
                  <LatestSelfAssessmentResultCard
                    date={latestTracking.completedLongDate}
                    indicator={{
                      isLoading: k10Score === undefined,
                      max: 50,
                      min: 10,
                      theme: ESelfAssessmentIndicatorTheme.yellow,
                      values: [{ value: k10Score }],
                    }}
                    subTitle="Your level of psychological distress is"
                    title={mentalHealthRiskCategory}
                  />
                </div>
              </div>
            ) : (
              <LatestSelfAssessmentResultCardEmpty />
            )}
          </div>

          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <BookmarkCard />
          </div>

          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <GoalsCard />
          </div>
        </div>
      </div>
    </Page>
  );
};

export { DashboardPage };
