import { useEffect, useState } from 'react';

import { IBookmark, IUseGetBookmarksForUserOnCompletedResponse } from '@netfront/ekardo-content-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import { useGetBookmarksForUser } from 'hooks';
import NextLink from 'next/link';

import { BookmarkCardEmpty } from './BookmarkCardEmpty';

import { ArrowRightIcon, DashboardCard, DashboardCardSkeleton } from '../../components';

export const BookmarkCard = () => {
  const { isAuthenticated } = useAuthentication();

  const [bookmarks, setBookmarks] = useState<IBookmark[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bookmarkIndex, setBookmarkIndex] = useState<number>(0);

  const handleGetBookmarksForUserCompleted = ({ bookmarks: returnedBookmarks }: IUseGetBookmarksForUserOnCompletedResponse) => {
    setBookmarks(returnedBookmarks);
    setIsLoading(false);
  };

  const { handleGetBookmarksForUser } = useGetBookmarksForUser({
    onCompleted: handleGetBookmarksForUserCompleted,
  });

  useEffect(() => {
    if (!isAuthenticated) return;
    handleGetBookmarksForUser({
      shouldIncludeContentPage: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const currentBookmarkPage = bookmarks[bookmarkIndex];

  return (
    <DashboardCard
      cardTooltip={{
        text: 'Any factsheets or page from the Steady program can be saved and viewed here for quick access.',
      }}
      href="/about-drugs"
      isCustomCard={(!isLoading && Boolean(bookmarks.length)) || (!isLoading && Boolean(!bookmarks.length))}
      linkLabel="Resources"
    >
      {!isLoading && bookmarks.length === 0 ? <BookmarkCardEmpty /> : null}
      {!isLoading && bookmarks.length ? (
        <>
          <div className="c-bookmark-card">
            <div className="absolute rounded-lg c-bookmark-card__layer--2" />
            <div className="absolute rounded-lg c-bookmark-card__layer--1" />

            <div className="flex border-2 border-primary rounded-lg overflow-hidden h-full relative min-h-64">
              <div className="w-3/5 bg-white p-6 flex flex-col justify-center border-r-2 border-primary">
                <p className="h6 color-secondary mb-2 weight-800">{currentBookmarkPage.contentPage.title}</p>
                <p>{currentBookmarkPage.contentPage.seoDescription}</p>
                <NextLink
                  href={`/about-drugs/${currentBookmarkPage.contentPage.url }`}
                >
                  <a className="color-primary mb-0 weight-800 flex items-center">
                    View
                    <div className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center ml-2">
                      <ArrowRightIcon additionalClassNames="w-2 h-2" />
                    </div>
                  </a>
                </NextLink>
              </div>
              <div className="flex items-center justify-center flex-1 bg-white p-4">
                <img
                  alt={currentBookmarkPage.contentPage.title}
                  className="w-full max-w-34"
                  src={currentBookmarkPage.contentPage.asset?.presignedUrl ?? '/images/stable-ground-logo.svg'}
                />
              </div>
            </div>
          </div>

          <div aria-label="Saved resources" className="flex items-center justify-center py-4">
            <button
              className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center p-0 mt-4px pointer rotate-180"
              onClick={() => setBookmarkIndex(bookmarkIndex === 0 ? bookmarks.length - 1 : bookmarkIndex - 1)}
            >
              <span className="sr-only">Previous saved resource</span>
              <ArrowRightIcon additionalClassNames="w-2 h-2" />
            </button>

            <span className="block mb-0 weight-800 mx-2">
              {bookmarkIndex + 1} / {bookmarks.length}
            </span>

            <button
              className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center p-0 mt-4px pointer"
              onClick={() => setBookmarkIndex(bookmarkIndex === bookmarks.length - 1 ? 0 : bookmarkIndex + 1)}
            >
              <span className="sr-only">Next saved resource</span>
              <ArrowRightIcon additionalClassNames="w-2 h-2" />
            </button>
          </div>
        </>
      ) : null}

      {isLoading ? <DashboardCardSkeleton direction="column" /> : null}
    </DashboardCard>
  );
};
