import { ChangeEvent, useEffect, useState } from 'react';

import {
  IUseGetAllTrackingOfStepOnCompletedResponse,
  IUserFlowStepTrack,
  IUseStartInstanceDateUserFlowStepOnCompletedResponse,
  useGetAllTrackingOfStep,
  useStartInstanceDateUserFlowStep,
} from '@netfront/ekardo-content-library';
import { DatePicker, Table, Textarea } from '@netfront/ui-library';
import { add, format, sub } from 'date-fns';
import { orderBy } from 'lodash';
import isEmpty from 'lodash.isempty';
import { Column } from 'react-table';

import { JOURNAL_PAGE_CONSTANTS } from './JournalPage.constants';

import { Button, formatJournalDate, getNewEntryText, IJournalEntry, ToolboxPageLayout } from '../../../components';
import { useProtectedRoute, useSetUserAnswerMultiLineText, useToast } from '../../../hooks';

const JournalPage = () => {
  const { isAuthenticated } = useProtectedRoute();

  const { handleToastError, handleToastSuccess } = useToast();

  const [userFlowStepTracks, setUserFlowStepTracks] = useState<IUserFlowStepTrack[]>([]);
  const [journalEntries, setJournalEntries] = useState<IJournalEntry[]>([]);
  const [startDate, setStartDate] = useState<Date | string>(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState<Date | string>(new Date());
  const [newDate, setNewDate] = useState<Date | string>(new Date());
  const [newEntry, setNewEntry] = useState<string>();

  const tableColumns: ReadonlyArray<Column<IJournalEntry>> = [
    {
      accessor: 'date',
      Cell: ({ value }: { value: string }) => <div>{value}</div>,
      Header: () => <div className="bg-white w-full p-4 rounded color-primary h5 text-left mb-0">Date</div>,
      width: '20%',
    },
    {
      accessor: 'entry',
      Cell: ({ value }: { value: string }) => <div>{value}</div>,
      Header: () => <div className="bg-white w-full p-4 rounded color-primary h5 text-left mb-0">Journal entry</div>,
    },
  ];

  const onNewDateChange = (value: Date) => {
    setNewDate(value);
  };

  const onStartDateChange = (value: Date) => {
    setStartDate(value);
  };

  const onEndDateChange = (value: Date) => {
    setEndDate(value);
  };

  const onNewEntryChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event;

    setNewEntry(value);
  };

  const onFilterClick = () => {
    handleGetAllTrackingOfStep({
      startDate: formatJournalDate(startDate),
      endDate: formatJournalDate(endDate),
      userFlowStepId: JOURNAL_PAGE_CONSTANTS.userFlowStepId,
      questionIds: JOURNAL_PAGE_CONSTANTS.questionIds,
    });
  };

  const handleSaveNewEntry = () => {
    handleStartInstanceDateUserFlowStep({
      instanceDate: formatJournalDate(newDate),
      userFlowStepId: JOURNAL_PAGE_CONSTANTS.userFlowStepId,
    });
  };

  const handleGetAllTrackingOfStepCompleted = ({
    userFlowStepTracks: returnedUserFlowStepTracks,
  }: IUseGetAllTrackingOfStepOnCompletedResponse) => {
    setUserFlowStepTracks(orderBy(returnedUserFlowStepTracks, 'instanceDate', 'desc'));
  };

  const handleStartUserFlowStepCompleted = ({
    userFlowStepTrack: { id: userFlowStepTrackId },
  }: IUseStartInstanceDateUserFlowStepOnCompletedResponse) => {
    handleSetUserAnswerMultiLineText({
      contentPageId: JOURNAL_PAGE_CONSTANTS.contentPageId,
      contentSnippetFormId: JOURNAL_PAGE_CONSTANTS.contentSnippetFormId,
      contentSnippetQuestionId: JOURNAL_PAGE_CONSTANTS.contentSnippetQuestionId,
      status: 'COMPLETE',
      text: String(newEntry),
      userFlowStepTrackId,
    });
  };

  const handleSetUserAnswerMultiLineTextCompleted = () => {
    handleGetAllTrackingOfStep({
      startDate: formatJournalDate(startDate),
      endDate: formatJournalDate(endDate),
      questionIds: JOURNAL_PAGE_CONSTANTS.questionIds,
      userFlowStepId: JOURNAL_PAGE_CONSTANTS.userFlowStepId,
    });

    handleToastSuccess({
      message: 'Journal entry added',
    });
  };

  const { handleGetAllTrackingOfStep } = useGetAllTrackingOfStep({
    onCompleted: handleGetAllTrackingOfStepCompleted,
  });

  const { handleStartInstanceDateUserFlowStep } = useStartInstanceDateUserFlowStep({
    onCompleted: handleStartUserFlowStepCompleted,
  });

  const { handleSetUserAnswerMultiLineText, isLoading: isSetUserAnswerLoading = false } = useSetUserAnswerMultiLineText({
    onCompleted: handleSetUserAnswerMultiLineTextCompleted,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetAllTrackingOfStep({
      startDate: formatJournalDate(startDate),
      endDate: formatJournalDate(endDate),
      userFlowStepId: JOURNAL_PAGE_CONSTANTS.userFlowStepId,
      questionIds: JOURNAL_PAGE_CONSTANTS.questionIds,
    }).catch((error) => {
      handleToastError({
        error,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    setJournalEntries(
      userFlowStepTracks.map(({ answers, instanceDate }) => ({
        date: (
          <div className="flex items-center bg-white w-full p-4 rounded">
            <img alt="Calendar" className="mr-2 max-w-6" src="/images/iconquer/calendar-icon.svg" />
            {format(new Date(instanceDate), 'dd/MM/yyyy')}
          </div>
        ),
        entry: <div className="bg-white w-full p-4 rounded">{!isEmpty(answers) && answers[0].text}</div>,
      })),
    );

    setNewEntry(getNewEntryText(userFlowStepTracks, newDate));
  }, [userFlowStepTracks, newDate]);

  return (
    <ToolboxPageLayout
      meta={{ seoDescription: 'Record your thoughts over time', seoTitle: JOURNAL_PAGE_CONSTANTS.pageTitle }}
      title={JOURNAL_PAGE_CONSTANTS.pageTitle}
    >
      <div className="ek-section">
        <p>
          My Journal is a place where you can record your thoughts over time. These might be about your fear of cancer recurrence, another
          topic, or your life more generally. Alternatively, you might prefer to use it to list the things that you’d like to do each day or
          what you achieved on a day. It is entirely up to you if and how you use it.
        </p>
      </div>
      <div className="ek-attention">
        <div className="c-snippet__icon">
          <img alt="Information" src="/images/iconquer/information-icon.svg" />
        </div>
        <p>Please use the space below to write whatever you like to save. Please save your thoughts using the button below.</p>
      </div>

      <div className="pt-8 pb-4 flex items-end justify-end">
        <div className="flex">
          <div className="flex flex-col">
            <DatePicker id="start-date" labelText="Start" selectedDate={startDate as Date} onSingleDateChangeHandler={onStartDateChange}/>

          </div>
          <div className="flex flex-col ml-4">
            <DatePicker id="end-date" labelText="End" minDate={add(new Date(), { years: 1 })} selectedDate={endDate as Date} onSingleDateChangeHandler={onEndDateChange}/>
          </div>
        </div>
        <Button className="ml-4" theme="greenOutline" onPress={onFilterClick}>
          Filter
        </Button>
      </div>

      <div className="c-table-journal">
        {/* @ts-expect-error Table props not recognising*/}
        {journalEntries.length > 0 && <Table<IJournalEntry> columns={tableColumns} data={journalEntries} />}
        <div className="flex flex-wrap mt-4 -mx-2">
          <div className="w-full md:w-1/5 px-2">
            {journalEntries.length === 0 && <div className="bg-white w-full p-4 rounded color-primary h5 text-left weight-600">Date</div>}
            <DatePicker id="selected-date" labelText="End" selectedDate={endDate as Date} isLabelHidden onSingleDateChangeHandler={onNewDateChange}/>
          </div>
          <div className="w-full md:w-4/5 px-2">
            {journalEntries.length === 0 && (
              <div className="bg-white w-full p-4 rounded color-primary h5 text-left weight-600">Journal entry</div>
            )}
            <Textarea
              id="entry"
              isDisabled={isSetUserAnswerLoading}
              labelText="Entry"
              name="entry"
              placeholder="Please enter your thoughts"
              value={newEntry}
              isLabelHidden
              onChange={onNewEntryChange}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end">
        <Button isDisabled={Boolean(!newEntry)} theme="blueOutline" onPress={handleSaveNewEntry}>
          Save journal entry
        </Button>
      </div>
    </ToolboxPageLayout>
  );
};

export { JournalPage };
