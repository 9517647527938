import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie, useJwtDecoder } from '@netfront/common-library';
import { useDomain, useRegisterUser } from '@netfront/gelada-identity-library';
import { Button, Checkbox, Input } from '@netfront/ui-library';
import { useFormik } from 'formik';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { AGENCIES_MAPPING, SMART_CODE_NOT_FOUND } from 'components/AgencySelectionSection/AgencySelectionSection.constants';


import { COMMUNITY_MAPPING, COMMUNITY_NOT_FOUND } from './RegisterPage.constants';

import {
  BreadcrumbHomeLink,
  BreadcrumbLink,
  CURRENT_STEP_DISPLAY_NAME,
  CURRENT_STEP_EMAIL,
  DisplayNameGeneratorInput,
  InformationBanner,
  IRegistrationStepOneValues,
  PAGE_TITLE,
  PasswordInputWithValidator,
  SingleFormPage,
  SPOTLIGHT_COOKIE,
} from '../../../components';
import { IGetSmartCodeOnCompletedResponse, useCurrentAgency, useGetSmartCode, useToast } from '../../../hooks';
import { scrollToTop } from '../../../utils';

const RegisterPage = () => {
  const { asPath, push } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();
  const { createCookie, setCookie, getTemporaryTokenCookie, deleteTemporaryTokenCookie } = useCookie();
  const { getDecodedJwt } = useJwtDecoder();
  const { getDomain } = useDomain();

  const [password, setPassword] = useState<string>('');
  const { smartCode: preselectedAgencyCode, setAgency } = useCurrentAgency();
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [communityCode, setCommunityCode] = useState<string>();
  const [currentStep, setCurrentStep] = useState<number>(CURRENT_STEP_EMAIL);

  const handleRegistrationCompleted = () => {
    setIsFormSubmitting(false);
    deleteTemporaryTokenCookie({ domain: getDomain() });

    const spotLightCookie = createCookie(SPOTLIGHT_COOKIE, 'true', {
      domain: getDomain(),
      storageExpiryOptions: {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'days',
          value: 9999,
        },
      },
    });

    setCookie(spotLightCookie);

    push('/activate-account');
  };

  const handleRegistrationError = (error: ApolloError) => {
    setIsFormSubmitting(false);

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleRegisterUser } = useRegisterUser({
    onCompleted: handleRegistrationCompleted,
    onError: handleRegistrationError,
  });

  const onGetSmartCodeCompleted = (data: IGetSmartCodeOnCompletedResponse) => {
    const { smartCode } = data;

    if (smartCode) {
      const { groups } = smartCode;
      if (!groups) {
        handleToastCustomError({ message: SMART_CODE_NOT_FOUND });
        return;
      }

      const [firstGroup] = groups;
      if (!firstGroup) {
        handleToastCustomError({ message: SMART_CODE_NOT_FOUND });
        return;
      }

      const { id } = firstGroup;
      const agency = AGENCIES_MAPPING.find((r) => r.id === id);
      const community = COMMUNITY_MAPPING.find( c => c.id === String(id));

      if (!agency) {
        handleToastCustomError({ message: SMART_CODE_NOT_FOUND });
        return;
      }

      if (!community) {
        handleToastCustomError({ message: COMMUNITY_NOT_FOUND });
        return;
      }

      setCommunityCode(community.key)
      setAgency(agency.type, smartCode.code);
      setCurrentStep(CURRENT_STEP_DISPLAY_NAME);
      scrollToTop();
    } else {
      handleToastCustomError({ message: 'The agency code used has not been recognised' });
    }
  };

  const { handleGetSmartCode, isLoading } = useGetSmartCode({ onCompleted: onGetSmartCodeCompleted });

  const formik = useFormik<IRegistrationStepOneValues>({
    initialValues: {
      confirmPassword: '',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      smartcode: preselectedAgencyCode ?? '',
      hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: false,
    },
    validationSchema: yup.object().shape({
      email: yup.string().label('Email').email().required('Email is required'),
      smartcode: yup.string().label('Agency code').required('Agency code is required'),
      displayName: yup.string().label('Display name').required('Display name is required'),
      hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: yup.bool().oneOf([true], `Agreement to the terms and conditions is required`),
      consent: yup.bool().oneOf([true], `Consent is required`),
    }),
    onSubmit: (values: IRegistrationStepOneValues) => {
      if (!isPasswordValid) {
        return;
      }

      setIsFormSubmitting(true);
      let instanceGuid = '';

      const temporaryToken = getTemporaryTokenCookie();
      if (temporaryToken) {
        const decodedJwt = getDecodedJwt(temporaryToken);
        instanceGuid = (decodedJwt as unknown as { temporary_session_id: string }).temporary_session_id;
      }

      handleRegisterUser({
        credential: {
          email: values.email,
          password,
        },
        user: {
          firstname: '',
          lastname: '',
          displayedName: values.displayName,
          smartCode: values.smartcode,
          instanceGuid,
          communityCode
        },
      });
    },
  });

  useEffect(() => {
    if (!preselectedAgencyCode) return;
    formik.setFieldValue('smartcode', preselectedAgencyCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedAgencyCode]);

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href="/" />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      containerSize="sm"
      meta={{ seoDescription: 'Create an account today', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      percentageSlider={{ percentage: currentStep === CURRENT_STEP_EMAIL ? 50 : 100 }}
      title={PAGE_TITLE}
    >
      <form onSubmit={formik.handleSubmit}>
        {currentStep === CURRENT_STEP_EMAIL && (
          <>
            <InformationBanner
              title="Your sign-up email is confidential"
              tooltipText="Your email and all information is confidential and will not be shared with your organisation"
              isDynamicallyPositioned
            >
              <p>
                To access our full range of free resources, please register for a confidential account. All we need is your email address
                and your agency&apos;s access code. You can use a personal email, it does not need to be a work email address.
              </p>
              <p className="mb-0">
                As part of the Stable Ground research study, de-identified data will be collected from website analytics (e.g., anonymised data collected on all users to identify trends and patterns in how visitors engage with the portal) for the purposes of evaluating and improving the website. For further information on how we use the information we collect on this site, please read the <strong>privacy and data</strong> section of our <NextLink href='/terms-and-conditions'><a className='color-primary weight-600'>terms and conditions</a></NextLink>.
              </p>
            </InformationBanner>

            <div className="mb-4">
              <Input
                id="email"
                labelText="Email"
                name="email"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? <div className='color-red-700'>{formik.errors.email}</div> : null}
            </div>

            <div className="mb-4">
              <Input
                id="smartcode"
                labelText="Agency code"
                name="smartcode"
                type="text"
                value={formik.values.smartcode}
                onChange={formik.handleChange}
              />
              {formik.touched.smartcode && formik.errors.smartcode ? <div className='color-red-700'>{formik.errors.smartcode}</div> : null}
            </div>

            <div className="mb-4">
              <PasswordInputWithValidator
                onChange={({ firstPassword, isPasswordValid: isPasswordValidValue }) => {
                  setPassword(firstPassword);
                  setIsPasswordValid(isPasswordValidValue);
                }}
              />
            </div>
          </>
        )}

        {currentStep === CURRENT_STEP_DISPLAY_NAME && (
          <>
            <InformationBanner title="Why do I need a display name?">
              <p className="mb-0">
              To ensure anonymity and protect confidentiality, we ask that you use an anonymous name in the online community. Click the arrow button to go through display name options.
              </p>
            </InformationBanner>

            <div className="mb-8">
              <label className="block mb-2" htmlFor="displayName">
                Display name
              </label>
              <DisplayNameGeneratorInput
                additionalClassNames="mr-2 pb-0"
                id="displayName"
                labelText="Display name"
                name="displayName"
                type="text"
                value={formik.values.displayName}
                isLabelHidden
                shouldGenerateSuggestionOnMount
                onChange={formik.handleChange}
                onGenerate={(displayName) => formik.setFieldValue('displayName', displayName)}
              />

              {formik.touched.displayName && formik.errors.displayName ? <div>{formik.errors.displayName}</div> : null}
            </div>

            <div className="mb-4">
              <div className="flex items-center mb-4">
                <Checkbox
                  id="hasReadAndAgreedToPrivacyStatementAndTermsAndConditions"
                  isChecked={formik.values.hasReadAndAgreedToPrivacyStatementAndTermsAndConditions}
                  labelText="Terms & conditions"
                  name="hasReadAndAgreedToPrivacyStatementAndTermsAndConditions"
                  value="hasReadAndAgreedToPrivacyStatementAndTermsAndConditions"
                  isLabelHidden
                  onChange={formik.handleChange}
                />
                <label className="mb-0 ml-4" htmlFor="hasReadAndAgreedToPrivacyStatementAndTermsAndConditions">
                  I have read and agreed to the
                  <NextLink href="/terms-and-conditions" target="_blank">
                    <a className="ml-1 color-primary text-underline" target='_blank'>Terms and Conditions</a>
                  </NextLink>
                </label>
              </div>
              {formik.touched.hasReadAndAgreedToPrivacyStatementAndTermsAndConditions &&
                formik.errors.hasReadAndAgreedToPrivacyStatementAndTermsAndConditions ? (
                  <div className='color-red-700'>{formik.errors.hasReadAndAgreedToPrivacyStatementAndTermsAndConditions}</div>
                ) : null}
            </div>
          </>
        )}

        <div className="flex items-center">
          <p className="flex-1">
            Already have an account?{''}
            <NextLink href="/login">
              <a className="ml-1 color-primary text-underline">Login</a>
            </NextLink>
          </p>

          {currentStep === CURRENT_STEP_EMAIL && (
            <Button
              // isDisabled={
              //   Boolean(!formik.values.email) || Boolean(formik.errors.email) || !isPasswordValid || Boolean(!formik.values.smartcode)
              // }
              isLoading={isLoading}
              text="Next"
              type="submit"
              variant="secondary"
              onClick={() => {
                if (!isPasswordValid) {
                  handleToastCustomError({ message: 'Please enter a valid password' });
                  return;
                };

                handleGetSmartCode({ code: formik.values.smartcode, projectId: String(process.env.REACT_APP_PROJECT_ID) });
              }}
            />
          )}

          {currentStep === CURRENT_STEP_DISPLAY_NAME && (
            <>
              <Button
                text="Back"
                type="button"
                variant="secondary"
                onClick={() => {
                  setCurrentStep(CURRENT_STEP_EMAIL);
                  scrollToTop();
                }}
              />
              <Button
                additionalClassNames="ml-4"
                isDisabled={
                  Boolean(!formik.values.displayName) ||
                  Boolean(formik.errors.displayName) ||
                  Boolean(formik.errors.hasReadAndAgreedToPrivacyStatementAndTermsAndConditions)
                }
                isLoading={isFormSubmitting}
                text="Register"
                type="submit"
                variant="secondary"
              />
            </>
          )}
        </div>
      </form>
    </SingleFormPage>
  );
};

export { RegisterPage };
