import { useEffect, useState } from 'react';

import { getAccessTokenCookie } from '@netfront/common-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { usePreventScroll } from '@react-aria/overlays';
import { useCurrentAgency } from 'hooks';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { MobileMenuItem } from '../../../components';
import { PRVIATE_HEADER_MENU_ITEMS, PUBLIC_HEADER_MENU_ITEMS } from '../../Header';
import { Link } from '../../Link';
import { MenuDropdown } from '../../MenuDropdown';

const MobileMenu = () => {
  usePreventScroll();

  const accessToken = getAccessTokenCookie();

  const { handleLogout } = useLogout();
  const { getDomain } = useDomain();
  const { removeAgency, getAgency } = useCurrentAgency();

  const [isAgencySelected, setIsAgencySelected] = useState<boolean>(false);

  const { push } = useRouter();

  const menuItems = accessToken
    ? PRVIATE_HEADER_MENU_ITEMS
    : isAgencySelected
      ? PUBLIC_HEADER_MENU_ITEMS.filter((r) => !r.isHiddenWhenAgencyCode)
      : PUBLIC_HEADER_MENU_ITEMS.filter((r) => !r.isHiddenWhenNoAgencyCode);

  const checkCookie = () => {
    const agency = getAgency();
    if (agency) {
      setIsAgencySelected(true);
      return;
    } else {
      setTimeout(() => checkCookie(), 1000);
    }
  };

  useEffect(() => {
    checkCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MenuDropdown className="bg-whisper flex flex-col h-screen index-1 overflow-y-auto top-0 xl:none">
      <div className="flex flex-1 flex-col">
        <nav className="flex-1 py-2">
          <ul className="m-0">
            {menuItems.map(({ href, id, label, subItems }) => (
              <MobileMenuItem key={`mobile menu-${id}`} href={href} id={id} label={label} subItems={subItems} />
            ))}
          </ul>
        </nav>

        <div className="mt-auto">
          <div className="bg-whisper flex items-center justify-center py-6">
            {!accessToken ? (
              <>
                <Button additionalClassNames="mr-4" linkButton={{ id: 'login', url: '/login' }} text="Login" variant="secondary" />
                <Button linkButton={{ id: 'register', url: '/register' }} text="Register" variant="cta" />
              </>
            ) : (
              <>
                <Button
                  additionalClassNames="mr-4"
                  linkButton={{ id: 'dashboard', url: '/dashboard' }}
                  text="Dashboard"
                  variant="secondary"
                />
                <Button
                  text="Logout"
                  variant="secondary"
                  onClick={() => {
                    removeAgency();
                    handleLogout({ domain: getDomain() });
                    push('/');
                  }}
                />
              </>
            )}
          </div>

          <NextLink href="/">
            <a className="bg-accent py-8 block text-center h5 mb-0 weight-600 color-black">Get help now</a>
          </NextLink>

          <div className="bg-secondary color-white">
            <div className="container py-6 text-center">
              <p className="block mb-0">&copy; {new Date().getFullYear()}, Stable Ground. All rights reserved.</p>
              <span>
                Crafted by{' '}
                <Link className="color-white" href="https://netfront.com.au/" rel="nofollow" target="_blank">
                  Netfront
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </MenuDropdown>
  );
};

export { MobileMenu };
