import { ChangeEvent, useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Button, CheckboxGroup, Dialog, Input, VideoPlayer } from '@netfront/ui-library';
import { useSocialContext } from 'contexts';
import { DBCommunity, DBPost } from 'interfaces';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import { getValidClassNames } from 'utils';
import uuid4 from 'uuid4';

import { PostMessageEditor } from 'components/Social/PostMessageEditor';

import { BreadcrumbLink, Page, DropzoneFileUpload } from '../../../../components';
import { DocumentPreviewer, getFileType, PostAssestPreview, POST_FEELINGS_OPTIONS, IPollitem, IconCross, BASE_POLL_ITEMS } from '../../../../components/Social';
import { useGetRootBreadcrumbItems, useToast } from '../../../../hooks';
import {
  CreatePostAssetMutation,
  CreatePostMutation,
  EAssetType,
  ECommunityRole,
  EPostFeeling,
  EPostType,
  EShareOption,
  useCreatePoll,
  useCreatePost,
  useCreatePostAsset,
  useDeleteAsset,
  useEditPost,
  useGetCommunitiesByUser,
  useGetPost,
  useUpdatePoll,
} from '../../../../services';
import { GetUserCommunitiesQueryResult } from '../../../../services/hooks';

const CreatePostPage = () => {
  const PAGE_TITLE = 'New post';

  const {
    query: { edit, communityKey },
  } = useRouter();
  const { asPath, push } = useRouter();
  const { handleToastError } = useToast();

  const isEditModeRequested = Boolean(edit);

  const { user } = useSocialContext();
  const { isToggled: isShareDialogOpen, toggle: toggleShareDialog } = useToggle();

  const [message, setMessage] = useState<string>('');
  const [isShareButtonDisabled, setIsShareButtonDisabled] = useState<boolean>(true);
  const [tags, setTags] = useState<string[]>([]);
  const [feeling, setFeeling] = useState<EPostFeeling>(EPostFeeling.None);
  const [existingPost, setExisitingPost] = useState<DBPost>();
  const [userCommunities, setUserCommunities] = useState<DBCommunity[]>([]);
  const [communityIds, setCommunityIds] = useState<string[]>([]);
  const [attachment, setAttachment] = useState<File | undefined>();
  const [hasSavedPost, setHasSavedPost] = useState<boolean>(false);
  const [hasSavedAttachment, setHasSavedAttachment] = useState<boolean>(false);
  const [pollTitle, setPollTitle] = useState<string>('');
  const [pollItems, setPollItems] = useState<IPollitem[]>(BASE_POLL_ITEMS);
  const [hasSavedPoll, setHasSavedPoll] = useState<boolean>(false);

  const canRedirect =  hasSavedPost && hasSavedAttachment && hasSavedPoll;

  const communtiesWithModeratorStatus = userCommunities.filter(
    ({ userConnection }) => userConnection?.role === ECommunityRole.Moderator || userConnection?.role === ECommunityRole.Owner,
  );

  // const hasAttachmentPermission = isEqual(
  //   communtiesWithModeratorStatus.map(({ id }) => String(id)),
  //   communityIds,
  // );

  const hasAttachmentPermission = communtiesWithModeratorStatus.map(({ id }) => String(id)).some(item => communityIds.includes(item))

  const hasMinimumPollData = Boolean(pollTitle) && Boolean(pollItems) && Boolean(!pollItems.find(({ title }) => Boolean(!title)));

  const addNewPollQuestion = () => {
    setPollItems([...pollItems, { id: uuid4(), title: '' }]);
  };

  const onPollTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setPollTitle(value);
  };

  const onPollItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;

    setPollItems(pollItems.map((post: IPollitem) => (String(post.id) === id ? { ...post, title: value } : post)));
  };

  const onRemovePollItem = (pollItemId: string) => {
    setPollItems(pollItems.filter(({ id }) => String(id) !== pollItemId));
  };

  const onPostCreateRequest = () => {
    setIsShareButtonDisabled(true);

    if (!isEmpty(existingPost)) {
      if (attachment && !existingPost.assets?.length) {
        executeCreatePostAsset({
          variables: {
            contentType: attachment.type,
            fileName: attachment.name,
            fileSizeInBytes: attachment.size,
            postId: Number(existingPost.id),
            type: getFileType(attachment.type) as EAssetType,
            alt: 'Post asset',
          },
        });
      }

      setHasSavedAttachment(true);

      editPost({
        variables: {
          request: {
            id: existingPost.id,
            feeling,
            message: message.replaceAll('@', '{@}'),
            postType: EPostType.Feed,
            shareOption: existingPost.shareOption as EShareOption,
            tags,
          },
        },
      });

      if (!existingPost.poll && hasMinimumPollData) {
        createPoll({
          variables: {
            postId: existingPost.id,
            items: pollItems.map(({ title }) => ({ title })),
            title: pollTitle,
          },
        });

        return;
      }

      existingPost.poll
        ? updatePoll({
          variables: {
            postId: existingPost.id,
            items: pollItems.map(({ title }) => ({ title })),
            title: pollTitle,
          },
        })
        : setHasSavedPoll(true);

      return;
    }

    createPostMutation({
      variables: {
        request: {
          message: message.replaceAll('@', '{@}'),
          postType: EPostType.Feed,
          shareOption: communityIds.includes('everyone') ? EShareOption.Everyone : EShareOption.Communities,
          tags,
          feeling,
          ...(!communityIds.includes('everyone') && { communities: communityIds.map((id) => Number(id)) }),
        },
      },
    });
  };

  const handleCreatePostCompleted = (response: CreatePostMutation) => {
    setHasSavedPost(true);
    const { post } = response;

    setHasSavedPoll(true);
    setHasSavedAttachment(true);

    if (hasMinimumPollData) {
      createPoll({
        variables: {
          postId: Number(post?.create?.id),
          items: pollItems.map(({ title }) => ({ title })),
          title: pollTitle,
        },
      });

      return;
    }

    if (attachment) {
      executeCreatePostAsset({
        variables: {
          contentType: attachment.type,
          fileName: attachment.name,
          fileSizeInBytes: attachment.size,
          postId: Number(post?.create?.id),
          type: getFileType(attachment.type) as EAssetType,
          alt: 'Post asset',
        },
      });

      return;
    }

  };

  const handleCreatePostError = () => {
    setIsShareButtonDisabled(false);
  };

  const handleGetPostCompleted = (returnedPost: DBPost | undefined) => {
    if (!returnedPost) return;

    const isExisitingPostOwner = returnedPost.author.id === user?.id;

    if (!isExisitingPostOwner) return;

    setExisitingPost(returnedPost);
    if (returnedPost.assets?.length) {
      const currentAttachment = new File([String(returnedPost.assets[0].presignedUrl)], String(returnedPost.assets[0].fileName), {
        type: returnedPost.assets[0].contentType,
      });

      setAttachment(currentAttachment);
    }
  };

  const handleCreatePostAssetCompleted = async (asset: CreatePostAssetMutation | undefined) => {
    const signedUrl = asset?.asset?.createPostAsset?.signedUrl;

    await fetch(signedUrl as string, {
      method: 'PUT',
      body: attachment,
    }).then(() => {
      setHasSavedAttachment(true);
    });
  };

  const handleGetUserCommunitiesCompleted = (communities: GetUserCommunitiesQueryResult[]) => {
    const returnedCommunities = communities.map(({ node }) => node);

    setUserCommunities(returnedCommunities);
    setCommunityIds([communityKey ? String(returnedCommunities.find(({ key }) => key === communityKey)?.id) : String(returnedCommunities[0].id)]);
  };

  const handleEditPostCompleted = () => {
    setHasSavedPost(true);
    setHasSavedAttachment(true); // Update once delete functionality is set
  };

  const redirectToFeed = () => {
    push('/community').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { createPostMutation } = useCreatePost({
    onCompleted: handleCreatePostCompleted,
    onError: handleCreatePostError,
  });

  const { getPost } = useGetPost({
    onCompleted: handleGetPostCompleted,
  });

  const { executeCreatePostAsset } = useCreatePostAsset({
    onCompleted: handleCreatePostAssetCompleted,
  });

  const { editPostMutation: editPost } = useEditPost({
    onCompleted: handleEditPostCompleted,
  });

  const { getUserCommunities } = useGetCommunitiesByUser({
    onCompleted: handleGetUserCommunitiesCompleted,
  });

  const { deleteAsset } = useDeleteAsset({
    onCompleted: () => {
      setAttachment(undefined);
    },
  });

  const { createPoll } = useCreatePoll({
    onCompleted: () => setHasSavedPoll(true),
  });

  const { updatePoll } = useUpdatePoll({
    onCompleted: () => setHasSavedPoll(true),
  });

  useEffect(() => {
    setTags(message.split(' ').filter((word) => word.startsWith('#') && word.length >= 2));
  }, [message]);

  useEffect(() => {
    if (!isEditModeRequested || isEmpty(user)) return;

    getPost({
      variables: {
        postId: Number(edit),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModeRequested, user]);

  useEffect(() => {
    getUserCommunities({
      variables: {
        shouldIncludeCommunities: true,
        shouldIncludeProfileImage: true,
        shouldIncludeUserConnection: true,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsShareButtonDisabled(Boolean(!message));
  }, [message]);

  useEffect(() => {
    if (isEmpty(existingPost)) return;

    setMessage(String(existingPost.message));

    if (existingPost.communityId) {
      setCommunityIds([String(existingPost.communityId)]);
    }

    if (existingPost.poll) {
      setPollTitle(existingPost.poll.title)
      setPollItems(existingPost.poll.items);
    };

    setFeeling(existingPost.feeling as EPostFeeling);
  }, [existingPost]);

  useEffect(() => {
    if (canRedirect) {
      redirectToFeed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRedirect]);


  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community">Community</BreadcrumbLink>, key: 'online-community' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{ seoDescription: `Create a new post`, seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-create-post-page__container">
        <>
          <button
            className="c-create-post-share c-create-post-page__card h-spacing"
            disabled={isEditModeRequested || userCommunities.length <= 1}
            onClick={() => {
              toggleShareDialog();
            }}
          >
            <span>
              Share with:{' '}
              <strong>
                {userCommunities
                  .filter(({ id }) => communityIds.includes(String(id)))
                  .reduce((prev, next, key) => {
                    if (key === 0) return next.title;
                    return prev + `, ${next.title}`;
                  }, '')}
              </strong>
            </span>
          </button>
          <Dialog
            isOpen={isShareDialogOpen}
            title="Share with"
            onClose={toggleShareDialog}
            onConfirm={() => {
              toggleShareDialog();
            }}
          >
            <CheckboxGroup
              items={userCommunities.map(({ id, title }) => ({ id: String(id), labelText: title, value: String(id) }))}
              legendText="Share with"
              name="shareOption"
              values={communityIds}
              isLabelHidden
              onChange={(values) => setCommunityIds(values)}
            />
          </Dialog>
        </>

        <PostMessageEditor
          value={message}
          onChange={(value) => {
            setMessage(value);
          }}
        />

        <div className="c-create-post-page__card h-spacing">
          <div className="flex flex-wrap">
            {POST_FEELINGS_OPTIONS.map(({ emoji, name, type }) => (
              <button
                key={`feeling-${type}`}
                className={getValidClassNames({
                  'flex items-baseline border-2 border-athens rounded py-2 px-3 mr-2 mb-2 pointer hover:bg-grey-50': true,
                  'border-primary color-primary': type === feeling,
                  'grayscale-1': type !== feeling,
                })}
                onClick={() => setFeeling(type)}
              >
                <span className="block mb-0 h5 mr-2 line-1">{emoji}</span>
                <span className="block mb-0">{name}</span>
              </button>
            ))}
          </div>
        </div>

        {hasAttachmentPermission && (
          <div className="c-create-post-page__card h-spacing-large flex-col items-start">
            <div className="flex items-center w-full mb-4">
              <span className="c-create-post__label flex-1">Create a poll</span>
            </div>
            <Input
              additionalClassNames="c-input--poll-title"
              id="poll-title"
              labelText="Poll title/question"
              name="poll-title"
              placeholder="Poll title/question"
              type="text"
              value={pollTitle}
              isLabelHidden
              onChange={onPollTitleChange}
            />
            {pollItems.map((question, key) => (
              <div key={key} className="c-poll-item">
                <Input
                  key={`poll-response-${key + 1}`}
                  id={`${String(question.id)}`}
                  labelText="Poll response"
                  name={`poll-response-${key + 1}`}
                  placeholder={`Poll response ${key + 1}`}
                  type="text"
                  value={question.title}
                  isLabelHidden
                  onChange={onPollItemChange}
                />
                <button onClick={() => onRemovePollItem(String(question.id))}>
                  <span className="h-hide-visually">Remove poll item</span>
                  <IconCross />
                </button>
              </div>
            ))}

            <Button
              additionalClassNames="h-spacing-large ml-auto"
              iconId="id_plus_icon"
              size='small'
              text="Add new response"
              onClick={addNewPollQuestion}
            />
          </div>
        )}

        {hasAttachmentPermission && !isShareDialogOpen && (
          <>
            <div className="c-create-post-page__card h-spacing-large flex-col items-start">
              <div className="flex items-center w-full">
                <span className="c-create-post__label flex-1">Add to your post</span>
              </div>
              <>
                {!attachment && (
                  <DropzoneFileUpload
                    additionalClassNames="mt-4"
                    labelText="Upload file to post"
                    isLabelHidden
                    onDrop={(acceptedFiles) => {
                      setAttachment(acceptedFiles[0]);
                    }}
                  />
                )}

                {attachment && getFileType(attachment.type) === EAssetType.Image && (
                  <>
                    <PostAssestPreview
                      onClick={() => {
                        if (existingPost && existingPost.assets?.length) {
                          deleteAsset({ variables: { assetId: String(existingPost.assets[0].assetId) } });
                        }
                        setAttachment(undefined);
                      }}
                    >
                      <img
                        alt="Post image preview"
                        className="c-image-preview__image"
                        src={
                          existingPost && existingPost.assets?.length
                            ? existingPost.assets[0].presignedUrl
                            : URL.createObjectURL(attachment)
                        }
                      />
                    </PostAssestPreview>
                  </>
                )}

                {attachment && getFileType(attachment.type) === EAssetType.Document && (
                  <DocumentPreviewer
                    file={attachment}
                    onCancel={() => {
                      if (existingPost && existingPost.assets?.length) {
                        deleteAsset({ variables: { assetId: String(existingPost.assets[0].assetId) } });
                      }
                      setAttachment(undefined);
                    }}
                  />
                )}

                {attachment && getFileType(attachment.type) === EAssetType.Video && (
                  <PostAssestPreview
                    onClick={() => {
                      if (existingPost && existingPost.assets?.length) {
                        deleteAsset({ variables: { assetId: String(existingPost.assets[0].assetId) } });
                      }

                      setAttachment(undefined);
                    }}
                  >
                    <VideoPlayer
                      additionalClassNames="c-post-video"
                      videoSrc={
                        existingPost && existingPost.assets?.length
                          ? String(existingPost.assets[0].presignedUrl)
                          : URL.createObjectURL(attachment)
                      }
                    />
                  </PostAssestPreview>
                )}
              </>
            </div>
          </>
        )}

        <div className="c-create-post-page__submit">
          <Button isDisabled={isShareButtonDisabled} text={existingPost ? 'Update' : 'Share'} onClick={onPostCreateRequest} />
        </div>
      </div>
    </Page>
  );
};

export { CreatePostPage };
