import { PostProps } from '../Post';

import { CommentGraphType, PostGraphType, PostInterfaceGraphType, PostShareGraphType } from '../../../services';

export interface IFeedPostsContext {
  onPostRelate?: (hasRelated: boolean, postId: number) => void;
  posts: PostInterfaceGraphType[];
}

export interface IApolloCursor {
  cursor: string;
}

export enum EFeedQuery {
  All = 'ALL',
  Members = 'MEMBERS',
  Moderators = 'MODERATORS',
}

export interface IPost extends IApolloCursor, PostGraphType, Pick<PostShareGraphType, 'originalPost'> {}
export interface IComment extends IApolloCursor, CommentGraphType {}

export interface FeedProps extends Pick<PostProps, 'isPreviewMode'> {
  authorId?: number;
  commentsFirst?: number;
  communityId?: number;
  first?: number;
  getLatestPosts?: number;
  message?: string;
  postId?: number;
  postSkeletonCount?: number;
  query?: EFeedQuery;
  shouldShowCommunitiesMessage?: boolean;
  status: 'ACTIVE' | 'DELETED' | 'UNPUBLISHED' | 'EMBARGO';
  tags?: string[];
}
