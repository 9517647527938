import { EReportType } from 'services';

export const REPORT_LABELS: Record<EReportType, string> = {
  HARASSMENT: 'Harassment',
  HATE_SPEECH: 'Hate speech',
  INAPPROPRIATE: 'Inappropriate',
  SPAM: 'Spam',
  SUICIDE_OR_SELF_INJURY: 'Suicide or self injury',
  VIOLENCE: 'Violence',
};

export const MAX_NESTED_COMMENT_DEPTH = 2;
export const REPLIES_FIRST = 10;