import { useEffect, useState } from 'react';

import { Button, ToggleSwitch } from '@netfront/ui-library';
import { useSocialContext } from 'contexts';
import { useRouter } from 'next/router';
import { CommunityMembersQueryResult, ECommunityRole, EConnectionStatus, useGetCommunityUsers, useSetIsOnline } from 'services';

import { CommunityFeedHeaderProps } from './CommunityFeedHeader.interfaces';

export const CommunityFeedHeader = ({ community }: CommunityFeedHeaderProps) => {
  const { push } = useRouter();
  const { user: loggedUser, updateUser } = useSocialContext();
  const { updateIsOnline } = useSetIsOnline();

  const [moderators, setModerators] = useState<CommunityMembersQueryResult[]>([]);

  const { getCommunityUsers } = useGetCommunityUsers({
    onCompleted: (allMembers) => {
      if(!allMembers.length) return;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      setModerators(allMembers.filter(({ node }) => node?.role === ECommunityRole.Moderator));
    },
  });

  useEffect(() => {
    if (!community) return;

    getCommunityUsers({
      variables: {
        communityId: Number(community.id),
        shouldIncludeUser: true,
        status: EConnectionStatus.Accepted,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  const isLoggedUserModerator = Boolean(moderators.map(({ node }) => node).find(({ user }) => user?.id === loggedUser?.id));

  const moderatorsOnline = moderators.filter(({ node }) => node.user?.isOnline).length
    ? moderators.filter(({ node }) => node.user?.isOnline).length
    : 'No';

  return (
    <div className="flex items-center mb-6 flex-col md:flex-row">

      <div className='flex items-center mb-8 md:mb-0'>
        <div className="bg-white p-4 rounded">
          <p className="mb-0 nowrap">{moderatorsOnline} moderators online</p>
        </div>

        {loggedUser && isLoggedUserModerator ? (
          <ToggleSwitch
            additionalClassNames="c-community-page__moderator-toggle ml-4"
            id="online"
            isChecked={Boolean(loggedUser.isOnline)}
            labelText="Show as online"
            onChange={() => {
              updateIsOnline({
                variables: {
                  isOnline: !loggedUser.isOnline,
                },
              }).then(() => {
                updateUser({
                  ...loggedUser,
                  isOnline: !loggedUser.isOnline,
                });

                setModerators(
                  moderators.map((cm) => {
                    if (cm.node.userId === loggedUser.id) {
                      return {
                        ...cm,
                        node: {
                          ...cm.node,
                          user: {
                            ...cm.node.user,
                            isOnline: !loggedUser.isOnline,
                          },
                        },
                      };
                    }

                    return cm;
                  }) as CommunityMembersQueryResult[],
                );
              });
            }}
          />
        ) : null}
      </div>
      <Button
        additionalClassNames="md:ml-auto"
        iconId="id_plus_icon"
        text="New post"
        onClick={() => {
          push(`/community/create-post?communityKey=${String(community?.key)}`);
        }}
      />
    </div>
  );
};
