import { getAccessTokenCookie } from '@netfront/common-library';
import { Button } from '@netfront/ui-library';
import NextLink from 'next/link';
import { capitalizeFirstLetter } from 'utils';

import { SelfAssessmentFeedbackProps } from './SelfAssessmentFeedback.interfaces';

import {
  EAssistItem,
  EK10DistressCategory,
  ERiskCategory,
  ESelfAssessmentIndicatorTheme,
  getK10DistressCategory,
  getMaximumRiskScoreFeedback,
  getPolySubstanceK10FeedBack,
  getReadinessToChangeInterpretation,
  getRecommendations,
  getScoreAndRiskText,
  getSingleSubstanceAssistFeedback,
  getSingleSubstanceK10FeedBack,
  getSubstanceRiskCategory,
  InformationBanner,
  SelfAssessmentIndicator,
  useGetSelfAssessmentResults,
} from '../../components';

export const SelfAssessmentFeedback = ({ shouldIncludeGoalCTA, trackingId }: SelfAssessmentFeedbackProps) => {
  const accessToken = getAccessTokenCookie();

  const {
    assistScores,
    allSubstancesExcludingSmoking,
    assistScoresExcludingSmoking,
    k10Score,
    mentalHealthRiskCategory,
    substanceRiskCategory,
    isK10Loading,
    isPolySubstanceUse,
    maxAssistCategory,
    k10SupplementaryScore,
    rtcScore,
    substanceSelections,
  } = useGetSelfAssessmentResults({
    trackingId: Number(trackingId),
  });

  const assistResults = allSubstancesExcludingSmoking
    .map((substance) => ({
      label: substance,
      value: assistScoresExcludingSmoking[substance],
    }))
    .filter(({ value }) => value !== 0);

  return (
    <>
      {accessToken ? null : (
        <InformationBanner>
          <div className="flex items-start md:items-center flex-col md:flex-row">
            <h2 className="h5 mb-4 md:mb-0 mr-4 flex-1">To save and track your results over time, register now!</h2>
            <Button linkButton={{ id: 'register', url: '/register' }} text="Register" />
          </div>
        </InformationBanner>
      )}
      <div className="bg-white p-8 rounded mb-4">
        <h2 className="h4 weight-800 color-secondary">Thank you</h2>
        {isPolySubstanceUse ? (
          <>
            <p>
              Thank you for completing this self-assessment. This can be an important step in looking after your health and wellbeing. There
              are many reasons why people use alcohol or other drugs. However, these can have negative impacts on your life - especially if
              you are also experiencing psychological distress.
            </p>
            <p>
              Below you will find more information on your alcohol and/or other drug use, as well as personalised feedback about your mental
              health. At the end of this feedback, we have provided suggestions for some next steps you may be interested in.
            </p>
            <p className="mb-0">
              Please note that this feedback is not intended to be a substitute for professional medical advice. For clinical diagnoses,
              please talk to a GP or a health professional.
            </p>
          </>
        ) : (
          <>
            <p>
              Thank you for completing this self-assessment. This can be an important step in looking after your health and wellbeing. There
              are many reasons why people use alcohol or other drugs. However, alcohol or other drugs can have negative impacts on your life
              - especially if you are also experiencing psychological distress.
            </p>
            <p>
              Below you will find more information on your alcohol and/or other drug use, as well as personalised feedback about your mental
              health. At the end of this feedback, we have provided suggestions for some next steps you may be interested in.
            </p>
            <p className="mb-0">
              Please note that this feedback is not intended to be a substitute for professional medical advice. For clinical diagnoses,
              please talk to a GP or a health professional.
            </p>
          </>
        )}
      </div>

      <div className="bg-white p-8 rounded mb-4">
        <p className="weight-600 color-secondary mb-2">Your substance use is at</p>
        <p className="h5 weight-800 color-primary mb-10">{substanceRiskCategory}</p>

        <h3 className="h6 color-secondary weight-800 mb-4">How is my alcohol and/or other drug use impacting me?</h3>

        {/* 
        <div aria-label="Alcohol and substance use score" className="mb-12">
          {assistResults.length ? <SelfAssessmentIndicator isLoading={isAssistLoading} max={39} values={assistResults} /> : null}
        </div> */}

        {assistResults.length ? (
          <div className="mb-10">
            {assistResults.map(({ label, value }) => (
              <div key={label} className="bg-grey-100 p-6 rounded mb-4">
                <span className="h5 weight-800 color-secondary block mb-4">
                  {label === EAssistItem.other ? 'Other substances' : capitalizeFirstLetter(label)}
                </span>
                <SelfAssessmentIndicator additionalClassNames="mb-4" max={39} values={[{ value }]} />
                {getScoreAndRiskText(
                  label,
                  assistScoresExcludingSmoking[label],
                  getSubstanceRiskCategory(label, assistScoresExcludingSmoking[label]) as ERiskCategory,
                )}
              </div>
            ))}
          </div>
        ) : null}

        {assistResults.length ? (
          <>
            <div className="mb-8">
              <h3 className="h6 color-secondary weight-800 mb-1">What does this mean?</h3>
              {isPolySubstanceUse
                ? getMaximumRiskScoreFeedback(maxAssistCategory as ERiskCategory)
                : getSingleSubstanceAssistFeedback(
                  allSubstancesExcludingSmoking[0],
                  assistScoresExcludingSmoking[allSubstancesExcludingSmoking[0]],
                  getSubstanceRiskCategory(
                    allSubstancesExcludingSmoking[0],
                    assistScoresExcludingSmoking[allSubstancesExcludingSmoking[0]],
                  ) as ERiskCategory,
                )}
            </div>
          </>
        ) : (
          <>{getSingleSubstanceAssistFeedback(EAssistItem.smoking, 0, ERiskCategory.no)}</>
        )}
      </div>
      {k10Score ? (
        <div className="bg-white p-8 rounded mb-4">
          <div className="mb-4">
            <p className="weight-600 color-secondary mb-2">Your level of psychological distress is</p>
            <p className="h5 weight-800 color-primary mb-6">{mentalHealthRiskCategory}</p>

            <div aria-label="Alcohol and substance use score" className="mb-12">
              <SelfAssessmentIndicator
                isLoading={isK10Loading}
                max={50}
                min={10}
                theme={ESelfAssessmentIndicatorTheme.yellow}
                values={[{ value: k10Score }]}
              />
            </div>
            <h3 className="h5 color-secondary weight-800">How might my mental health be impacted?</h3>

            {isPolySubstanceUse
              ? getPolySubstanceK10FeedBack(getK10DistressCategory(k10Score) as EK10DistressCategory, Number(k10SupplementaryScore))
              : getSingleSubstanceK10FeedBack(getK10DistressCategory(k10Score) as EK10DistressCategory, Number(k10SupplementaryScore))}
          </div>

          <div>
            <p>
              It is common for there to be a relationship between a person’s mental health and their alcohol or other drug use, which means
              that if you decide to make a change to either your mental health or drug use, you may notice a change in the other.
            </p>
            <p className="mb-4">
              If you are distressed <strong>right now</strong> and want to talk to someone, you can reach out to one of these{' '}
              <strong>helplines below</strong> for support. For more resources on mental health and psychological distress, visit the <NextLink href='https://www.blackdoginstitute.org.au/' target='_blank'><a className='color-primary weight-600' href='https://www.blackdoginstitute.org.au/' rel="noreferrer" target='_blank'>Black Dog Institute</a></NextLink>.
            </p>

            <InformationBanner additionalClassNames="mb-0">
              <p className="mb-4">
                Lifeline: 13 11 14 | Beyond Blue: 1300 22 4636 | Suicide Call Back Service: 1300 659 467 | Alcohol and Drug Information
                Services NSW: 1800 250 015 | Family Drug Support: 1300 368 186
              </p>
              <strong>If you require urgent medical attention or are at risk of harming yourself or someone else, call 000 now.</strong>
            </InformationBanner>
          </div>
        </div>
      ) : null}

      {/* Both templates receive */}

      {/* Both templates receive */}
      {substanceSelections.includes(EAssistItem.smoking) && assistScores.smoking !== 0 && (
        <div className="bg-white p-8 rounded mb-4">
          <h3 className="h5 color-secondary weight-800 mb-1">Smoking</h3>
          <p className="mb-0">
            If you would like help quitting or cutting down, visit quit.org.au or call the free and confidential Quitline on 13 78 48.
          </p>
        </div>
      )}
      {/*  */}

      <div className="bg-white p-8 rounded">
        <h3 className="h5 color-secondary weight-800 mb-6">Next steps – what can I do now?</h3>

        {rtcScore ? (
          <>
            <p className="mb-6">{getReadinessToChangeInterpretation(rtcScore)?.description}</p>
            {getRecommendations(
              isPolySubstanceUse
                ? (maxAssistCategory as ERiskCategory)
                : (getSubstanceRiskCategory(
                  allSubstancesExcludingSmoking[0],
                  assistScoresExcludingSmoking[allSubstancesExcludingSmoking[0]],
                ) as ERiskCategory),
            )}
          </>
        ) : (
          getRecommendations(maxAssistCategory as ERiskCategory)
        )}
        {/*  */}
      </div>

      {accessToken && shouldIncludeGoalCTA ? (
        <>
          <InformationBanner additionalClassNames="mt-4" theme="hint">
            <div className="py-2">
              <h3 className="h5 mb-2">Are you interested in setting some goals?</h3>
              <p className="mb-0">
                If this personalised feedback made you want to take some action, goal setting can be a great way to get started. Click the
                button below, or find Goal Setting under the Resources tab.
              </p>
            </div>
          </InformationBanner>
          <div className="flex items-center justify-center flex-col md:flex-row">
            <Button additionalClassNames="md:mr-4" linkButton={{ id: 'goals', url: '/goals' }} text="Yes, I would like to set goals" />

            <Button
              linkButton={{ id: 'dashboard', url: '/dashboard' }}
              text="Not right now, take me to the dashboard"
              variant="secondary"
            />
          </div>
        </>
      ) : null}
    </>
  );
};
