import React, { useRef, forwardRef } from 'react';

import { Alert, Icon, Label } from '@netfront/ui-library';
import cx from 'classnames';
import Dropzone, { DropzoneRef } from 'react-dropzone';

import { getAcceptedFileTypeMap } from './DropzoneFileUpload.helpers';
import { DropzoneFileUploadProps } from './DropzoneFileUpload.types';

const DropzoneFileUpload = forwardRef<HTMLInputElement, DropzoneFileUploadProps>(
  (
    {
      additionalClassNames,
      children,
      file,
      fileType = '',
      maxFileSize,
      isAcceptMultipleFiles = false,
      isLabelHidden = false,
      isRequired = false,
      labelText,
      acceptedFileTypes,
      onDrop,
      isDisabled = false,
      name = '',
      id,
      uploadText,
      hasUploadIcon = true,
      tooltipPosition = 'end',
      tooltipText,
    }: DropzoneFileUploadProps,
    ref,
  ) => {
    const dropzoneRef = useRef<DropzoneRef>(null);

    const handleOpenDialog = () => {
      if (!dropzoneRef.current) {
        return;
      }

      dropzoneRef.current.open();
    };

    const uploadTextString = uploadText ?? `Drag ${fileType} file here or click to upload`;

    return (
      <>
        {!file ? (
          <>
            <Label
              forId={id ?? ''}
              isHidden={isLabelHidden}
              labelText={labelText}
              tooltipPosition={tooltipPosition}
              tooltipText={tooltipText}
            />
            <button
              className={cx('c-dropzone--container', additionalClassNames)}
              data-testid="qa-dropzone-file-upload-container"
              id="dropzone-file-upload"
              type="button"
              onClick={handleOpenDialog}
            >
              <Dropzone
                ref={dropzoneRef}
                accept={getAcceptedFileTypeMap(acceptedFileTypes)}
                disabled={isDisabled}
                multiple={isAcceptMultipleFiles}
                noClick
                noKeyboard
                onDropAccepted={onDrop}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                  return (
                    <div
                      data-testid="qa-dropzone-file-upload"
                      {...getRootProps({
                        className: cx('c-dropzone', {
                          'c-dropzone--is-active': Boolean(isDragActive),
                        }),
                      })}
                    >
                      {hasUploadIcon && <Icon className="c-dropzone__download-icon" id="id_upload_icon" />}

                      <span className="c-dropzone__drag-text">{uploadTextString}</span>
                      <input ref={ref} id={id} name={name} required={isRequired} {...getInputProps()} />
                      {isDragReject && <Alert iconId="id_error_icon" message="Incorrect file type" />}

                      {maxFileSize ? (
                        <span className="c-dropzone__file-size-text">File under {maxFileSize}mb</span>
                      ) : (
                        !isAcceptMultipleFiles && (
                          <span>
                            <strong>Maximum</strong> 1 file
                          </span>
                        )
                      )}
                    </div>
                  );
                }}
              </Dropzone>
            </button>
          </>
        ) : (
          <>{children}</>
        )}
      </>
    );
  },
);

DropzoneFileUpload.displayName = 'DropzoneFileUpload';

export { DropzoneFileUpload };
