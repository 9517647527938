import { Button } from '@netfront/ui-library';
import NextLink from 'next/link';

import { SelfAssessmentFeedbackFollowupProps } from './SelfAssessmentFeedbackFollowup.interfaces';

import {
  EAssistItem,
  EK10DistressCategory,
  ERiskCategory,
  getAssistCategoryDifference,
  getAssistItemDifferenceFeedback,
  getK10DistressCategory,
  getK10Feedback,
  getRecommendations,
  getSubstanceRiskCategory,
  InformationBanner,
  useGetSelfAssessmentResults,
} from '../../components';


export const SelfAssessmentFeedbackFollowup = ({
  prevTrackingId,
  shouldIncludeGoalCTA,
  trackingId,
}: SelfAssessmentFeedbackFollowupProps) => {
  const {
    assistScores,
    allSubstancesExcludingSmoking,
    assistScoresExcludingSmoking,
    k10Score,
    maxAssistCategory,
    k10SupplementaryScore,
    substanceSelections,
  } = useGetSelfAssessmentResults({
    trackingId: Number(trackingId),
  });

  const {
    assistScoresExcludingSmoking: previousAssistScoresExcludingSmoking,
    k10Score: previousK10Score,
    maxAssistCategory: previousMaxAssistCategory,
  } = useGetSelfAssessmentResults({
    trackingId: Number(prevTrackingId),
  });

  const assistResults = allSubstancesExcludingSmoking.map((substance) => ({
    label: substance,
    value: assistScoresExcludingSmoking[substance],
  }));

  return (
    <>
      <div className="bg-white p-8 rounded mb-4">
        <p>Thank you for completing this follow-up self-assessment. </p>
        <p className="mb-8">
          Below, you will find your results from this self-assessment, as well as your previous results to allow you to see how things have
          changed since then.{' '}
        </p>

        <h2 className="h5 color-secondary mb-4">Why am I seeing this again? </h2>
        <p>
          We want to adapt our recommendations for you based on how you are doing now. In this feedback, you can find some suggestions on
          next steps based on the self-assessment you just completed. These next steps could be the same as your previous ones, or they
          could have changed.{' '}
        </p>
      </div>

      <div className="bg-white p-8 rounded mb-4">
        <h3 className="h4 color-secondary weight-800">How is my alcohol and/or other drug use impacting me?</h3>
        {assistResults.length ? (
          <div className="mb-12">
            {assistResults.map(({ label }) =>
              getAssistItemDifferenceFeedback({
                substance: label,
                latestRisk: getSubstanceRiskCategory(label, assistScoresExcludingSmoking[label]) as ERiskCategory,
                latestScore: assistScoresExcludingSmoking[label],
                previousRisk: getSubstanceRiskCategory(label, previousAssistScoresExcludingSmoking[label]) as ERiskCategory,
                previousScore: previousAssistScoresExcludingSmoking[label],
              }),
            )}
          </div>
        ) : null}

        <h3 className="h4 color-secondary weight-800">What does this mean?</h3>

        {(maxAssistCategory as ERiskCategory) === ERiskCategory.no ? (
          <>
            <p>
              You indicated that you have not used alcohol or other drugs in the last three months, which places you in the{' '}
              <strong>no risk</strong> category.
            </p>
            <p>
              This means that your current pattern of alcohol or other drug use is <strong>not currently</strong> putting you at risk of
              experiencing problems in the physical health, mental health, relationship, work, financial, and legal areas of your life.
            </p>
            <p>
              However, it is important to note that this can change if you begin to drink alcohol or use other drugs. If you are struggling
              with your mental health, you might be more likely to engage in risky use of alcohol or other drugs.
            </p>
          </>
        ) : null}

        {(maxAssistCategory as ERiskCategory) === ERiskCategory.low ? (
          <>
            <p>
              Overall, this means that your current pattern of substance use is putting you at <strong>low risk</strong> of experiencing
              problems in the physical health, mental health, relationship, work, financial and legal areas of your life.{' '}
            </p>
            <p>
              Using more than one drug at the same time or in close proximity can be dangerous and unpredictable and, in some circumstances,
              it can be fatal. You may be at increased risk of experiencing an overdose, poisoning, loss of consciousness and heart attacks.
              Learn more about mixing drugs <NextLink href='/about-drugs'>here</NextLink>.{' '}
            </p>
            <p>
              Your current pattern of alcohol or other drug use suggests it is unlikely you are experiencing dependence at the moment.
              However, it is important to know that anyone can develop tolerance and dependence on alcohol and other drugs, both physically
              and psychologically.
            </p>
          </>
        ) : null}

        {(maxAssistCategory as ERiskCategory) === ERiskCategory.moderate ? (
          <>
            <p>
              Overall, this means that your current pattern of substance use is putting you at <strong>moderate risk</strong> of
              experiencing problems in the physical health, mental health, relationship, work, financial and legal areas of your life.{' '}
            </p>
            <p>
              Using more than one drug at the same time or in close proximity can be dangerous and unpredictable and, in some circumstances,
              it can be fatal. You may be at increased risk of experiencing an overdose, poisoning, loss of consciousness and heart attacks.
              Learn more about mixing drugs <NextLink href='/about-drugs'>here</NextLink>.
            </p>
            <p>
              For at least one drugs you use, you are at risk of experiencing dependence. It is important to know that anyone can develop
              tolerance and dependence on drugs, both physically and psychologically.
            </p>
          </>
        ) : null}

        {(maxAssistCategory as ERiskCategory) === ERiskCategory.high ? (
          <>
            <p>
              Overall, this means that for at least one of the drugs you use, your current pattern of use is putting you at{' '}
              <strong>high risk</strong> of experiencing severe problems. Your drug use could be having an impact on your physical and
              mental health, your living situation, your employment and your social relationships.
            </p>
            <p>
              Using more than one drug at the same time or in close proximity can be dangerous and unpredictable and, in some circumstances,
              it can be fatal. You may be at increased risk of experiencing an overdose, poisoning, loss of consciousness and heart attacks.
              Learn more about mixing drugs <NextLink href='/about-drugs'>here</NextLink>.
            </p>
            <p>
              You are also likely to currently be experiencing dependence on at least one of the drugs you use. If you do choose to stop or
              reduce your drug use, it is highly recommended you seek medical advice from a health practitioner first.
            </p>
          </>
        ) : null}
      </div>

      <div className="bg-white p-8 rounded mb-4">
        <h3 className="h4 color-secondary weight-800">Next steps – what can I do now?</h3>

        <div className="mb-8">
          {getAssistCategoryDifference({
            latestCategory: maxAssistCategory as ERiskCategory,
            previousCategory: previousMaxAssistCategory as ERiskCategory,
          })}
        </div>

        <h3 className="h5 color-secondary weight-800 mb-6">Treatment recommendations</h3>
        {getRecommendations(maxAssistCategory as ERiskCategory)}
      </div>

      <div className="bg-white p-8 rounded mb-4">
        {k10Score && k10SupplementaryScore ? (
          <>
            {' '}
            <h3 className="h4 color-secondary weight-800">How might my mental health be impacted?</h3>
            {getK10Feedback(getK10DistressCategory(k10Score) as EK10DistressCategory, k10SupplementaryScore)}
            {previousK10Score === undefined ? null : (
              <>
                {k10Score > previousK10Score ? (
                  <p>
                    Since your last self-assessment, your level of psychological distress has <strong>increased</strong>.
                  </p>
                ) : null}
                {k10Score < previousK10Score ? (
                  <p>
                    Since your last self-assessment, your level of psychological distress has <strong>decreased</strong>.
                  </p>
                ) : null}
                {k10Score === previousK10Score ? (
                  <p>
                    Compared to your last self-assessment, your level of psychological distress has <strong>stayed the same</strong>.
                  </p>
                ) : null}
              </>
            )}
          </>
        ) : null}

        <p>
          It is common for there to be a relationship between a person’s mental health and their alcohol or other drug use, which means that
          if you decide to make a change to either your mental health or drug use, you may notice a change in the other.
        </p>
        <p className="mb-4">
          If you are distressed <strong>right now</strong> and want to talk to someone, you can reach out to one of these{' '}
          <strong>helplines below</strong> for support. For more resources on mental health and psychological distress, visit the <NextLink href='https://www.blackdoginstitute.org.au/' target='_blank'><a className='color-primary weight-600' href='https://www.blackdoginstitute.org.au/' rel="noreferrer" target='_blank'>Black Dog Institute</a></NextLink>.
        </p>

        <InformationBanner>
          <p className="mb-4">
            Lifeline: 13 11 14 | Beyond Blue: 1300 22 4636 | Suicide Call Back Service: 1300 659 467 | Alcohol and Drug Information Services
            NSW: 1800 250 015 | Family Drug Support: 1300 368 186
          </p>
          <strong>If you require urgent medical attention or are at risk of harming yourself or someone else, call 000 now.</strong>
        </InformationBanner>

        {substanceSelections.includes(EAssistItem.smoking) && assistScores.smoking !== 0 && (
          <div>
            <h3 className="h5 color-secondary weight-800 mb-1">Smoking</h3>
            <p>
              If you would like help quitting or cutting down, visit quit.org.au or call the free and confidential Quitline on 13 78 48.
            </p>
          </div>
        )}
      </div>

      <div className="bg-white p-8 rounded">
        <h3 className="h4 color-secondary weight-800">See you in 3 months</h3>
        <p>
          You have reached the end of your personalised feedback. We recommend coming back in another 3 months to do the Stable Ground
          online self-assessment again. This way, we can provide up-to-date information and resources that are personalised to how you are
          doing at that time.
        </p>
      </div>

      {shouldIncludeGoalCTA ? (
        <>
          <InformationBanner additionalClassNames="mt-4" theme="hint">
            <div className="py-2">
              <h3 className="h5 mb-2">Have your results inspired you to take action?</h3>
              <p className="mb-0">
                Goal setting can be a great way to get started. If you have previously set some goals on Stable Ground, this could be a good
                time to reflect on how those are going. If you haven&#39;t set any goals yet, you can start today. Click the button below,
                or find Goal Setting under the Resources tab.
              </p>
            </div>
          </InformationBanner>
          <div className="flex items-center justify-center flex-col md:flex-row">
            <Button additionalClassNames="md:mr-4" linkButton={{ id: 'goals', url: '/goals' }} text="Yes, I would like to set goals" />

            <Button
              linkButton={{ id: 'dashboard', url: '/dashboard' }}
              text="Not right now, take me to the dashboard"
              variant="secondary"
            />
          </div>
        </>
      ) : null}
    </>
  );
};
