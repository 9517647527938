import { useEffect } from 'react';

import { getAccessTokenCookie } from '@netfront/common-library';
import { useContentPageContext } from '@netfront/ekardo-content-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { useCurrentAgency } from 'hooks';
import NextLink from 'next/link';

import Resources from 'components/Resources/Resources';

import { AgencySelectionSection, Announce, CTASection, FeaturesSection, Page } from '../../../components';

const LandingPage = () => {
  const { isAuthenticated } = useAuthentication();
  const { dispatch } = useContentPageContext();
  const { getAgency } = useCurrentAgency();
  const accessToken = getAccessTokenCookie();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    dispatch({
      type: 'unloadContentPage',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Page
      hasPrivateLayout={false}
      meta={{
        seoDescription: 'Providing free resources and support information for NSW government agencies',
        seoTitle: 'Stable Ground',
      }}
      title="Stable Ground"
    >
      <Announce location="home" />
      <section className="bg-primary py-16 relative">
        <div className="container text-center">
          <h1 className="color-white mb-10">Providing free resources and support information for NSW government agencies</h1>

          <h2 className="color-white h5 mb-12 weight-normal">Stable Ground provides resources and support information for NSW social and emergency services employees as part of a research study. Our mission is to offer information and support for employees with their use of alcohol or other drugs. We aim to empower and equip workers with the knowledge and tools they need to maintain a stable and fulfilling life, both on and off the job. Please read our  <NextLink href='/terms-and-conditions' target='_blank'><a className="h5 color-white text-underline">terms and conditions</a></NextLink> of use before using Stable Ground</h2>

          <h2 className="color-white h4 mb-12">Let us find the right resources for you</h2>

          <div className="flex items-center justify-center">
            {!getAgency() && (
              <Button
                additionalClassNames="mr-4"
                linkButton={{ id: 'recommendations', url: '#agencies' }}
                text="Select your agency to get started"
                variant="cta"
              />
            )}
            {accessToken || getAgency() ? (
              <Button linkButton={{ id: 'resources', url: '/about-drugs' }} text="Access resources" variant="secondary" />
            ) : null}
          </div>
        </div>
        <img alt="Pattern" aria-hidden="true" className="img-hero-diamond" src="/images/background-graphic-dark.svg" />
      </section>

      <AgencySelectionSection />
      <FeaturesSection />
      <Resources />

      <CTASection />
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export { LandingPage };
