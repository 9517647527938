import { useEffect, useState } from 'react';

import { Button } from '@netfront/ui-library';

export const KanziPrintButton = () => {
  const [isHydrated, setIsHydrated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onPrintClick = () => {
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    (window as unknown as { Kanzi_Manual_Commands: { GeneratePdf(cb: (e: string) => void) } }).Kanzi_Manual_Commands.GeneratePdf(
      (e: string) => {
        setIsLoading(false);

        // Créer un élément d'ancrage pour le lien de téléchargement
        const downloadLink = document.createElement('a');
        downloadLink.href = e;
        downloadLink.download = 'filename.pdf'; // remplacez "nom-du-fichier" par le nom de fichier souhaité
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
    );
  };

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isHydrated) return <></>;
  return <Button iconId={'id_pdf_icon'} isLoading={isLoading} text="Print PDF" onClick={onPrintClick} />;
};
