import { Checkbox } from '@netfront/ui-library';
import NextLink from 'next/link';

import { IconChevronRight } from '../Icons';
import { UserAvatar } from '../UserAvatar';

import { ListCardProps } from './ListCard.interfaces';

const ListCard = ({
  avatarImage,
  avatarTitle,
  children,
  checkBox,
  description,
  displayName,
  hasArrow,
  isModerator,
  isOnline,
  shouldHideAvatar = false,
  href,
  tag,
}: ListCardProps) => {
  const CardContent = () => (
    <>
      {checkBox && <Checkbox {...checkBox} />}
      <div className="c-list-card__header">
        {!shouldHideAvatar && <UserAvatar avatar={avatarImage} displayName={avatarTitle ?? displayName} isModerator={isModerator} isOnline={isOnline} size="small" />}

        <div>
          {displayName && <span className="c-list-card__display-name">{displayName}</span>}
          {description && <span className="c-list-card__description">{description}</span>}
        </div>
      </div>
      {tag && (
        <div className="c-list-card__tag">
          <span>{tag}</span>
        </div>
      )}
      {children}
      {hasArrow && <IconChevronRight additionalClassNames="c-list-card__arrow" />}
    </>
  );

  return href ? (
    <NextLink className="c-list-card" href={href}>
      <a className="c-list-card">
        <CardContent />
      </a>
    </NextLink>
  ) : (
    <div className="c-list-card">
      <CardContent />
    </div>
  );
};

export { ListCard };
