import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  FormFieldContainer,
  FORM_ELEMENT_CSS_IDENTIFIERS,
  ILoginOnCompletedResponse,
  LoginForm,
  REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS,
  saveAuthenticationData,
  useDomain,
  useLogin,
  useLogout,
} from '@netfront/gelada-identity-library';
import { Dialog } from '@netfront/ui-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import {
  AGENCIES_MAPPING,
  SMART_CODE_NOT_FOUND,
  USER_NOT_LINKED_TO_ANY_AGENCY,
} from 'components/AgencySelectionSection/AgencySelectionSection.constants';

import {
  Button,
  USER_NOT_ACTIVATED_MESSAGE,
  SingleFormPage,
  BreadcrumbHomeLink,
  InformationBanner,
  BreadcrumbLink,
} from '../../../components';
import { useCurrentAgency, useGetRegisterUrl, useIsMounted, useToast } from '../../../hooks';

const LoginPage = () => {
  const PAGE_TITLE = 'Login';
  const HAS_CONSENTED_ALREADY = 'HAS_CONSENTED';

  const { button: buttonElementCssId, container: containerElementCssId, register: registerElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const { form: formBlockCssId } = REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS;

  const { setAgency } = useCurrentAgency();
  const [isResendActivationCodeDialogOpen, setIsResendActivationCodeDialogOpen] = useState<boolean>(false);

  const { asPath, prefetch, query, replace, reload } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();
  const { registerUrl } = useGetRegisterUrl();
  const { isMounted } = useIsMounted();
  const { getDomain, isDomainReady } = useDomain();

  const { requestedRoute } = query;

  const { handleLogout } = useLogout();

  const navigateToUrlAfterLoginCompleted = requestedRoute ? String(requestedRoute) : '/dashboard';

  const handleLoginCompleted = ({ accessToken, refreshToken, user }: ILoginOnCompletedResponse) => {
    if (!user) return;

    saveAuthenticationData({
      accessToken,
      refreshToken,
      user,
      accessTokenOptionalCookieAttributes: {
        domain: getDomain(),
      },
      refreshTokenOptionalCookieAttributes: {
        domain: getDomain(),
      },
      userOptionalCookieAttributes: {
        domain: getDomain(),
      },
    });

    const { memberships } = user;
    if (!memberships) {
      handleToastCustomError({ message: SMART_CODE_NOT_FOUND });
      return;
    }

    const [firstGroup] = memberships.filter((r) => r.type === 'GROUP');
    if (!firstGroup) {
      handleToastCustomError({ message: USER_NOT_LINKED_TO_ANY_AGENCY });
      return;
    }

    const { group } = firstGroup;
    const agency = AGENCIES_MAPPING.find((r) => r.id === group?.id);

    if (!agency) {
      handleToastCustomError({ message: USER_NOT_LINKED_TO_ANY_AGENCY });
      return;
    }

    setAgency(agency.type, '');
    localStorage.removeItem(HAS_CONSENTED_ALREADY);

    replace({ pathname: navigateToUrlAfterLoginCompleted }).then(() => reload());
  };

  const handleLoginError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    const { message } = error;

    if (message === USER_NOT_ACTIVATED_MESSAGE) {
      setIsResendActivationCodeDialogOpen(true);
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleLogin, isLoading = false } = useLogin({
    onCompleted: handleLoginCompleted,
    onError: handleLoginError,
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    prefetch(navigateToUrlAfterLoginCompleted).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );

    handleLogout({ domain: getDomain() });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const registerButton = (
    <FormFieldContainer
      css={{
        blockId: formBlockCssId,
        elementId: `${registerElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
      }}
    >
      <NextLink href={registerUrl}>
        <a className="ml-1 color-primary text-underline">Register</a>
      </NextLink>
    </FormFieldContainer>
  );

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href="/" />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: 'Access your account', seoTitle: PAGE_TITLE }}
      title={PAGE_TITLE}
    >
      <InformationBanner title="Get access to your resources">
        <p className="mb-0">Login to your account to get access to your resources and results</p>
      </InformationBanner>

      <LoginForm
        buttonClassName="c-button c-button--secondary"
        forgotPasswordUrl="/forgot-password"
        isSubmitting={isLoading}
        registerButton={registerButton}
        isTogglePasswordVisible
        onLogin={async (login, password) => {
          await handleLogin({
            login,
            password,
            shouldIncludeUserMembershipsGroup: true,
            shouldIncludeUserCustomFields: true,
            shouldIncludeUserCredential: true,
            shouldIncludeUserMemberships: true,
          });
        }}
      />

      {isMounted && (
        <Dialog
          isOpen={isResendActivationCodeDialogOpen}
          title="This account has been deactivated"
          onClose={() => setIsResendActivationCodeDialogOpen(false)}
        >
          <p className="mb-8">This email has been deactivated, please sign up with a different email address</p>

          <Button theme="default" onPress={() => setIsResendActivationCodeDialogOpen(false)}>
            Close
          </Button>
        </Dialog>
      )}
    </SingleFormPage>
  );
};

export { LoginPage };
