import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  CMSContentPage,
  DBContentPageNavigationDirectionType,
  getUpdatedContentGroupingElementsFromQuestionActionConditions,
  IContentPage,
  IDBContentPageConfigurationNavigation,
  IHandleGetNextPageParams,
  IHandleGetPreviousPageParams,
  IUseContentPageNavigationOnCompletedResponse,
  IUseContentPageOnCompletedResponse,
  IUseGetAnswersOnCompletedResponse,
  IUseGetUserFlowStepContentPagesOnCompletedResponse,
  IUseStartContentPageCompletionOnCompletedResponse,
  IUseStartUserFlowStepUsingUrlsOnCompletedResponse,
  Toolkit,
  useContentPage,
  useContentPageContext,
  useContentPageNavigation,
  useGetAnswers,
  useGetUserFlowStepContentPages,
  useTracking,
} from '@netfront/ekardo-content-library';
import { usePerson } from '@netfront/gelada-identity-library';
import { Breadcrumbs, IBreadcrumbItem } from '@netfront/ui-library';
import { Skeleton ,
  BreadcrumbItem,
  ContentHeader,
  ContentNavigationDrawer,
  ContentNavigationLink,
  Page,
  NavigationButton,
  ProgressCircle,
  BreadcrumbLink,
  CaretLeftIcon,
  FOCUS_SUBSTANCE_RESPONSE_ID_MAP,
  FOCUS_SUBSTANCE_RESPONSE_ID_OTHER,
  KanziPrintButton,
} from 'components';
import kebabCase from 'lodash.kebabcase';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { getValidClassNames } from 'utils';

import { getFlattenContentPages, handleConfidenceSliderValue, handleImportantSliderValue } from './ModuleContentPage.helpers';

import {
  IUseGetUserFlowStepProgressionOnCompletedResponse,
  useGetAgencyUserFlowUrl,
  useGetRootBreadcrumbItems,
  useGetUserFlowStepProgression,
  useProtectedRoute,
  useToast,
} from '../../../hooks';

const ModuleContentPage = () => {
  const { isAuthenticated } = useProtectedRoute();

  const { dispatch } = useContentPageContext();
  const { firstName } = usePerson();
  const { push, query } = useRouter();
  const { handleToastError } = useToast();

  const { contentPageUrl, userFlowStepUrl, /* userFlowUrl = 'program' */ } = query;

  const { userFlowUrl } = useGetAgencyUserFlowUrl();

  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([]);
  const [contentPageCompletionId, setContentPageCompletionId] = useState<number>();
  const [contentPageId, setContentPageId] = useState<number>();
  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [contentPage, setContentPage] = useState<IContentPage>();
  const [firstContentPageInStep, setFirstContentPageInStep] = useState<IContentPage>();
  const [lastContentPageInSession, setLastContentPageInSession] = useState<IContentPage>();
  const [hasContentPageData, setHasContentPageData] = useState<boolean>(false);
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState<boolean>(false);
  const [isFirstContentPageInSession, setIsFirstContentPageInSession] = useState<boolean>(false);
  const [isLastContentPageInSession, setIsLastContentPageInSession] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true);
  const [moduleTitle, setModuleTitle] = useState<string>('');
  const [nextContentPageUrl, setNextContentPageUrl] = useState<string>('');
  const [sessionPercentageCompleted, setSessionPercentageCompleted] = useState<number>(0);
  const [userFlowStepTrackId, setUserFlowStepTrackId] = useState<number>();
  const [navigationDirection, setNavigationDirection] = useState<DBContentPageNavigationDirectionType>('NEXT');


  const handleEndContentPageCompletionCompleted = async () => {
    setContentPageCompletionId(undefined);

    setContentPages((previousContentPages) =>
      previousContentPages.map((page) => {
        const { id } = page;

        if (id !== contentPageId) {
          return page;
        }

        return {
          ...page,
          isCompleted: true,
        };
      }),
    );

    if (navigationDirection === 'NEXT' && isLastContentPageInSession) {
      await handleEndUserFlowStep({
        userFlowStepTrackId: Number(userFlowStepTrackId),
      });

      return;
    }

    push(`/program/${String(userFlowStepUrl)}/${String(nextContentPageUrl)}`).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleEndContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleEndUserFlowStepCompleted = () => {
    push('/program').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleEndUserFlowStepError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetContentPageCompleted = async (data: IUseContentPageOnCompletedResponse) => {
    const { contentPage: currentContentPage } = data;

    const { id: returnedContentPageId } = currentContentPage;

    const { contentSectionContainers, contentSections, contentSnippets, sectionGroups } =
      getUpdatedContentGroupingElementsFromQuestionActionConditions(currentContentPage);

    const flattenedContentPages = getFlattenContentPages(contentPages);

    const { contentPageConfigurationNavigation } =  flattenedContentPages.find(({ id }) => id === returnedContentPageId) as IContentPage;


    const { enableBackButton: hasEnableBackButton = true, enableNextButton: hasEnableNextButton = true  } = contentPageConfigurationNavigation ?? {} as IDBContentPageConfigurationNavigation;

    const isFirstContentPage = Boolean(firstContentPageInStep && firstContentPageInStep.id === returnedContentPageId);

    const isLastContentPage = Boolean(lastContentPageInSession && lastContentPageInSession.id === returnedContentPageId);

    setContentPage(currentContentPage);
    setContentPageId(returnedContentPageId);
    setIsBackButtonEnabled(hasEnableBackButton);
    setIsNextButtonEnabled(hasEnableNextButton);
    setIsFirstContentPageInSession(isFirstContentPage);
    setIsLastContentPageInSession(isLastContentPage);

    dispatch({
      payload: {
        contentPage: currentContentPage,
      },
      type: 'loadContentPage',
    });

    dispatch({
      payload: {
        placeholders: {
          '[NAME]': firstName,
        },
      },
      type: 'savePlaceholders',
    });

    dispatch({
      payload: {
        contentSectionContainers,
        contentSections,
        contentSnippets,
        sectionGroups,
      },
      type: 'applyQuestionActions',
    });

    await handleStartContentPageCompletion({
      contentPageId: returnedContentPageId,
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleGetContentPageError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetNextOrPreviousPage = (direction: DBContentPageNavigationDirectionType) => {
    setNavigationDirection(direction);

    setHasContentPageData(false);

    const params = {
      contentPageId: Number(contentPageId),
      userFlowStepTrackId: Number(userFlowStepTrackId),
    };

    if (direction === 'NEXT') {
      handleGetNextPage(params as IHandleGetNextPageParams);
      return;
    }

    handleGetPreviousPage(params as IHandleGetPreviousPageParams);
  };

  const handleGetNextOrPreviousContentPageCompleted = async (data: IUseContentPageNavigationOnCompletedResponse) => {
    const { nextOrPreviousContentPage } = data;

    if (nextOrPreviousContentPage) {
      setNextContentPageUrl(nextOrPreviousContentPage.url);
    }

    await handleEndContentPageCompletion({
      contentPageCompletionId: Number(contentPageCompletionId),
    });
  };

  const handleGetNextOrPreviousContentPageError = (error: ApolloError) => {
    setHasContentPageData(true);

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetUserFlowStepContentPagesCompleted = (data: IUseGetUserFlowStepContentPagesOnCompletedResponse) => {
    const { userFlowStep: returnedUserFlowStep } = data;

    const { contentGroup, firstContentPage, lastContentPage } = returnedUserFlowStep;
    const { contentPages: groupContentPages = [], title = '' } = contentGroup ?? {};

    localStorage.setItem('lastViewedUserflowStep', String(returnedUserFlowStep.id));
    setModuleTitle(title);
    setContentPages(groupContentPages);
    setFirstContentPageInStep(firstContentPage);
    setLastContentPageInSession(lastContentPage);
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const handleGetUserFlowStepContentPagesError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleStartContentPageCompletionCompleted = ({
    contentPageCompletion: { id },
  }: IUseStartContentPageCompletionOnCompletedResponse) => {
    setContentPageCompletionId(id);

    handleGetAnswers({
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleStartContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleStartUserFlowStepUsingUrlsCompleted = async ({
    userFlowStepTrackId: returnedUserFlowStepTrackId,
  }: IUseStartUserFlowStepUsingUrlsOnCompletedResponse) => {
    setUserFlowStepTrackId(returnedUserFlowStepTrackId);

    await handleGetUserFlowStepContentPages({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      topLevelOnly: true,
      userFlowStepTrackId: returnedUserFlowStepTrackId,
      userFlowStepUrl: String(userFlowStepUrl),
      userFlowUrl: String(userFlowUrl),
    });
  };

  const handleStartUserFlowStepUsingUrlsError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };
  const handleGetUserFlowStepProgressionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
    setSessionPercentageCompleted(0);
  };

  const handleGetUserFlowStepProgressionCompleted = (response: IUseGetUserFlowStepProgressionOnCompletedResponse) => {
    const { percentageCompleted } = response;
    setSessionPercentageCompleted(percentageCompleted);
  };

  const { handleGetUserFlowStepProgression } = useGetUserFlowStepProgression({
    fetchPolicy: 'no-cache',
    onCompleted: handleGetUserFlowStepProgressionCompleted,
    onError: handleGetUserFlowStepProgressionError,
  });

  const { handleGetContentPage } = useContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: handleGetContentPageCompleted,
    onError: handleGetContentPageError,
  });

  const { handleGetNextPage, handleGetPreviousPage } = useContentPageNavigation({
    onCompleted: handleGetNextOrPreviousContentPageCompleted,
    onError: handleGetNextOrPreviousContentPageError,
  });

  const { handleEndContentPageCompletion, handleEndUserFlowStep, handleStartContentPageCompletion, handleStartUserFlowStepUsingUrls } =
    useTracking({
      onEndContentPageCompletionCompleted: handleEndContentPageCompletionCompleted,
      onEndContentPageCompletionError: handleEndContentPageCompletionError,
      onEndUserFlowStepCompleted: handleEndUserFlowStepCompleted,
      onEndUserFlowStepError: handleEndUserFlowStepError,
      onStartContentPageCompletionCompleted: handleStartContentPageCompletionCompleted,
      onStartContentPageCompletionError: handleStartContentPageCompletionError,
      onStartUserFlowStepUsingUrlsCompleted: handleStartUserFlowStepUsingUrlsCompleted,
      onStartUserFlowStepUsingUrlsError: handleStartUserFlowStepUsingUrlsError,
    });

  const { handleGetUserFlowStepContentPages } = useGetUserFlowStepContentPages({
    onCompleted: handleGetUserFlowStepContentPagesCompleted,
    onError: handleGetUserFlowStepContentPagesError,
  });

  const handleGetAnswersCompleted = (data?: IUseGetAnswersOnCompletedResponse) => {
    if (!data) {
      return;
    }
    const { answers } = data;

    const focusSubstanceResponse = answers.find(
      ({ contentSnippetQuestionId }) => contentSnippetQuestionId === 26276,
    )?.questionResponseRadioId;

    const focusSubstanceOtherResponse = answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === 26277)?.text;

    const focusSubstancePlaholder =
      focusSubstanceResponse === FOCUS_SUBSTANCE_RESPONSE_ID_OTHER
        ? focusSubstanceOtherResponse
        : FOCUS_SUBSTANCE_RESPONSE_ID_MAP[String(focusSubstanceResponse)];

    dispatch({
      payload: {
        placeholders: {
          '[FOCUS_SUBSTANCE]': focusSubstancePlaholder,
        },
      },
      type: 'savePlaceholders',
    });

    setHasContentPageData(true);
  };

  const { handleGetAnswers } = useGetAnswers({
    onCompleted: handleGetAnswersCompleted,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!(userFlowUrl && userFlowStepUrl)) {
      return;
    }

    handleStartUserFlowStepUsingUrls({
      userFlowUrl: String(userFlowUrl),
      userFlowStepUrl: String(userFlowStepUrl),
    }).catch((error) => {
      handleToastError({
        error,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userFlowUrl, userFlowStepUrl]);

  useEffect(() => {
    if (!(contentPageUrl && userFlowStepTrackId && contentPages.length)) {
      return;
    }

    const flattenedContentPages = getFlattenContentPages(contentPages);

    const { id } = flattenedContentPages.find(({ url }) => url === contentPageUrl) as IContentPage;

    handleGetContentPage({
      contentPageId: id,
      userFlowStepTrackId: Number(userFlowStepTrackId),
      withAnswers: true,
    });

    handleGetUserFlowStepProgression({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      stepUrl: String(userFlowStepUrl),
      userFlowUrl: String(userFlowUrl),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPageUrl, userFlowStepTrackId, contentPages.length]);

  useEffect(() => {
    if (!contentPage) {
      return;
    }

    setBreadcrumbItems([
      ...rootBreadCrumbItems,
      {
        content: <BreadcrumbLink href="/program">Program</BreadcrumbLink>,
        key: 'program',
      },
      {
        content: (
          <a className="color-black" href={firstContentPageInStep?.url}>
            {moduleTitle}
          </a>
        ),
        key: 'Module',
      },
      {
        content: <BreadcrumbItem text={contentPage.title} />,
        key: kebabCase(contentPage.title),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage, firstContentPageInStep, moduleTitle, userFlowStepUrl]);

  useEffect(() => {
    if (!hasContentPageData) {
      return;
    }

    if (contentPageId) {
      handleImportantSliderValue(contentPageId, (sliderValue) => {
        dispatch({
          payload: {
            placeholders: {
              '[IMPORTANCE_RULER]': String(sliderValue),
              '[IMPORTANCE_RULER_HIGHER_VALUE]': Number(sliderValue) + 2 > 10 ? '10' : String(Number(sliderValue) + 2),
            },
          },
          type: 'savePlaceholders',
        });
      });

      handleConfidenceSliderValue(contentPageId, (confidenceValue) => {
        dispatch({
          payload: {
            placeholders: {
              '[CONFIDENCE_SLIDER_VALUE]': String(confidenceValue),
              '[CONFIDENCE_SLIDER_HIGHER_VALUE]': Number(confidenceValue) + 2 > 10 ? '10' : String(Number(confidenceValue) + 2),
            },
          },
          type: 'savePlaceholders',
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasContentPageData, contentPageId]);

  return (
    <Page
      bodyClassName="mb-18 md:mb-0"
      className="flex flex-col flex-1"
      meta={{ seoDescription: contentPage?.seoDescription, seoTitle: contentPage?.seoTitle }}
      title={contentPage ? contentPage.title : 'Content'}
      hasPrivateLayout
      shouldExcludeFooter
    >
      <div className="bg-white border-b border-athens none md:block">
        <div className="container container-lg">
          <Breadcrumbs items={breadcrumbItems} />
        </div>
      </div>
      <div className="flex flex-1">
        <ContentNavigationDrawer isLoading={!hasContentPageData}>
          {moduleTitle && (
            <div className="flex items-center mb-4">
              <div className="flex-1">
                <NextLink href="/program">
                  <a className="flex items-baseline">
                    <div className="flex items-center justify-center bg-primary color-white rounded-circle h-5 w-5 mr-2">
                      <CaretLeftIcon additionalClassNames="h-3 w-3" />
                    </div>
                    <span className="block mb-0 h6 weight-600 color-secondary">Program dashboard</span>
                  </a>
                </NextLink>
              </div>
              <ProgressCircle percentageCompleted={Math.round(sessionPercentageCompleted)} size="sm" />
            </div>
          )}

          {contentPages.map(({ contentPageChildren, id, isCompleted = false, title, url }) => {
            const fullHref = `/program/${String(userFlowStepUrl)}/${String(url)}`;
            const isActive = id === contentPageId;

            return (
              <>
                <ContentNavigationLink
                  key={`content-navigation-link-${id}`}
                  href={fullHref}
                  isActive={isActive}
                  isCompleted={isCompleted}
                  // isDisabled={!hasBeenViewed}
                  isSubMenuOpenOnDefault={
                    contentPage?.id === id ||
                    Boolean(contentPageChildren.filter(({ id: subPageId }) => subPageId === contentPage?.id).length)
                  }
                  text={title}
                  {...(contentPageChildren.length && {
                    subMenu: (
                      <ol className="pl-4 ml-4 mt-2 mb-0 border-l-2 border-whisper">
                        {contentPageChildren.map(
                          ({
                            // hasBeenViewed: hasSubBeenViewed,
                            id: subId,
                            isCompleted: isSubCompleted = false,
                            title: subTitle,
                            url: subUrl,
                          }) => {
                            const subFullHref = `/program/${String(userFlowStepUrl)}/${String(subUrl)}`;
                            const isSubActive = subId === contentPageId;

                            return (
                              <ContentNavigationLink
                                key={`content-navigation-link-${subId}`}
                                href={subFullHref}
                                isActive={isSubActive}
                                isCompleted={isSubCompleted}
                                // isDisabled={!hasSubBeenViewed}
                                text={subTitle}
                              />
                            );
                          },
                        )}
                      </ol>
                    ),
                  })}
                />
              </>
            );
          })}
        </ContentNavigationDrawer>

        <article className={getValidClassNames({
          "md:w-3/5 mx-auto px-8 pt-8 pb-12 w-full": true,
          [`c-cms-page-${String(contentPage?.url)}`]: true
        })}>
          <div className="flex flex-col md:flex-row md:items-center md:mb-4 no-print">
            <div className="flex-1">{hasContentPageData && <Toolkit contentPageId={Number(contentPageId)} />}</div>
            <div className='mb-4 md:mb-0'>
              <KanziPrintButton />
            </div>
          </div>

          <ContentHeader
            chapterTitle={moduleTitle}
            isLoading={!hasContentPageData}
            pageTitle={contentPage?.title}
            percentageCompleted={sessionPercentageCompleted}
          />

          {hasContentPageData ? (
            <CMSContentPage userFlowStepTrackId={Number(userFlowStepTrackId)} />
          ) : (
            <div className="ek-section">
              <Skeleton height="5rem" width="100%" />
            </div>
          )}

          <div className="items-center flex mt-6 no-print">
            {!isFirstContentPageInSession && (
              <NavigationButton
                direction={'PREVIOUS'}
                isDisabled={!isBackButtonEnabled}
                isLoading={!hasContentPageData}
                moduleTitle={moduleTitle}
                onPress={() => handleGetNextOrPreviousPage('PREVIOUS')}
              />
            )}

            {!isLastContentPageInSession ? (
              <NavigationButton
                direction={'NEXT'}
                isDisabled={!isNextButtonEnabled}
                isLoading={!hasContentPageData}
                moduleTitle={moduleTitle}
                onPress={() => handleGetNextOrPreviousPage('NEXT')}
              />
            ) : (
              <NavigationButton
                direction={'NEXT'}
                isDisabled={!isNextButtonEnabled}
                isLoading={!hasContentPageData}
                moduleTitle={moduleTitle}
                pageTitle={`Finish ${moduleTitle.toLowerCase()}`}
                onPress={() => handleGetNextOrPreviousPage('NEXT')}
              />
            )}
          </div>
        </article>
      </div>
    </Page>
  );
};

export { ModuleContentPage };
