import { useEffect, useState } from 'react';

import { IUserFlowStepTrack, useGetUserFlowStepTracksWithAnswers } from '@netfront/ekardo-content-library';
import { Button, Select } from '@netfront/ui-library';
import { differenceInDays, format, getYear } from 'date-fns';
import { useRouter } from 'next/router';

import { BreadcrumbLink, CLUSTER_ID_K10, InformationBanner, Page, SelfAssessmentFeedback, SelfAssessmentPrompt } from '../../../components';
import { useGetRootBreadcrumbItems } from '../../../hooks';

const FeedbackRecentResultsPage = () => {
  const PAGE_TITLE = 'Recent results';
  const { asPath } = useRouter();

  const [results, setResults] = useState<IUserFlowStepTrack[]>([]);

  const [currentTrackingId, setCurrentTrackingId] = useState<number>();

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { handleGetUserFlowStepTracksWithAnswers, isLoading } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: ({ userFlowStepTracks }) => {
      const sortedCompletedTrackings = [...userFlowStepTracks].filter(({ isCompleted }) => isCompleted).reverse();
      setResults(sortedCompletedTrackings);
      setCurrentTrackingId(sortedCompletedTrackings[0].id);
    },
  });

  useEffect(() => {
    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      userFlowStepUrls: ['self-assessment'],
      clusterIds: [CLUSTER_ID_K10],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{
        seoDescription: 'View your most recent self-assessment results and personalised feedback',
        seoTitle: 'Recent results – Self-assessment | Stable Ground',
      }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="container pb-12">
        <InformationBanner additionalClassNames="mb-4">
          <p>Review your latest self-assessment results.</p>
          <p className="mb-0">You can also use the date button below to view previous self-assessments.</p>
        </InformationBanner>
        <div className="flex items-center mb-4">
          {!isLoading && results.length === 0 ? (
            <div className="bg-white border border-athens p-8 rounded text-center w-full">
              <p className="color-secondary mb-4">Click below to complete a new self-assessment</p>
              <Button linkButton={{ id: 'self-assessment', url: '/self-assessment/introduction' }} text="Take self-assessment" />
            </div>
          ) : null}

          {!isLoading && results.length ? (
            <div className="flex items-center flex-1">
              <div className="flex-1">
                <SelfAssessmentPrompt daysElapsed={differenceInDays(new Date(), new Date(results[0].completedLongDate))} />
              </div>
              <div>
                <Select
                  id="date"
                  labelText="Date range"
                  name="date"
                  options={results
                    .sort((a, b) => new Date(b.completedLongDate).getTime() - new Date(a.completedLongDate).getTime())
                    .map(({ completedLongDate, id }) => ({
                      id,
                      name: `${format(new Date(completedLongDate), 'do')} of ${format(new Date(completedLongDate), 'MMMM')} ${getYear(
                        new Date(completedLongDate),
                      )}`,
                      value: id,
                    }))}
                  value={currentTrackingId}
                  isLabelHidden
                  onChange={({ currentTarget: { value } }) => setCurrentTrackingId(Number(value))}
                />
              </div>
            </div>
          ) : null}
        </div>

        {!isLoading && currentTrackingId ? (
          <>
            <SelfAssessmentFeedback trackingId={currentTrackingId} />
            <InformationBanner additionalClassNames="mt-4" theme="hint">
              <div className="py-2">
                <h3 className="h5 mb-2">Have your results inspired you to take action?</h3>
                <p className="mb-0">
                  Goal setting can be a great way to get started. If you have previously set some goals on Stable Ground, this could be a
                  good time to reflect on how those are going. If you haven&#39;t set any goals yet, you can start today. Click the button
                  below, or find Goal Setting under the Resources tab.
                </p>
              </div>
            </InformationBanner>
            <div className="flex items-center justify-center flex-col md:flex-row">
              <Button additionalClassNames="md:mr-4" linkButton={{ id: 'goals', url: '/goals' }} text="Yes, I would like to set goals" />

              <Button
                linkButton={{ id: 'dashboard', url: '/dashboard' }}
                text="Not right now, take me to the dashboard"
                variant="secondary"
              />
            </div>
          </>
        ) : null}
      </div>
    </Page>
  );
};

export { FeedbackRecentResultsPage };
