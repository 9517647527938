import { useEffect, useState } from 'react';

import { useCookie } from '@netfront/common-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { Avatar, Dropdown } from '@netfront/ui-library';
import { DASHBOARD_PAGE_CONSTANTS, SPOTLIGHT_COOKIE, Spotlight } from 'components';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { CallBackProps } from 'react-joyride';

import { OnlineStatus } from '../OnlineStatus';

import { USER_MENU_DATA } from './UserMenu.constants';
import { UserMenuProps } from './UserMenu.interfaces';

import { useCurrentAgency, useToast } from '../../../hooks';

const UserMenu = ({ avatar, displayName, isOnline = false }: UserMenuProps) => {
  const { push } = useRouter();
  const { getDomain, isDomainReady } = useDomain();
  const { handleToastError } = useToast();
  const { removeAgency } = useCurrentAgency();
  const { getCookieValue, deleteCookie } = useCookie();

  const [domain, setDomain] = useState<string>('');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [spotLightData, setSpotLightData] = useState<CallBackProps>();
  const [shouldShowSpolightTour, setShouldShowSpolightTour] = useState<boolean>(false);

  const onDisplayContent = (isDisplay: boolean) => setIsOpen(isDisplay);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());

   
    setShouldShowSpolightTour(getCookieValue(SPOTLIGHT_COOKIE) === 'true');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);


  const { handleLogout } = useLogout({
    onCompleted: () => {
      removeAgency();
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  const isDisableSpotLightClose = spotLightData?.step.title === 'Self assessment';

  useEffect(() => {
    if(!spotLightData) {
      setShouldShowSpolightTour(false)
    }

    if(spotLightData?.status === 'ready') {
      setShouldShowSpolightTour(false)
      deleteCookie(SPOTLIGHT_COOKIE, { domain: getDomain(), path: '/' })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotLightData]);

  return (
    <>
      {shouldShowSpolightTour && (
        <Spotlight
          disableCloseOnEsc={true}
          disableOverlayClose={isDisableSpotLightClose}
          hideCloseButton={true}
          spotlightItem={DASHBOARD_PAGE_CONSTANTS.spotLightItem}
          onSpotLightData={setSpotLightData}
        />
      )}
      
      <Dropdown
        additionalClassNames="c-user-menu spotlight-item--4"
        dropdownId="avatar-menu"
        isDisplayContent={isOpen}
        trigger={
          <>
            {isOnline && <OnlineStatus size="sm" />}
            <Avatar {...(avatar && { image: <img alt={displayName} src={avatar} /> })} size="medium" title={displayName} />
          </>
        }
        onDisplayContent={onDisplayContent}
      >
        <ul className="c-user-menu__dropdown">
          {USER_MENU_DATA.map(({ href, id, label }) => (
            <li key={id} className="c-user-menu__item">
              <NextLink href={href}>
                <a className="c-user-menu__link">{label}</a>
              </NextLink>
            </li>
          ))}

          <li className="c-user-menu__item">
            <button className="c-user-menu__link" onClick={() => setShouldShowSpolightTour(true)}>
                Take a tour
            </button>
          </li>

          <li className="c-user-menu__item">
            <button 
              className="c-user-menu__link c-user-menu__logout" 
              onClick={() => handleLogout({ domain, path: '/' })}
            >
              Logout
            </button>
          </li>
        </ul>
      </Dropdown>
    </>
  );
};

export { UserMenu };
