import { useToggle } from '@netfront/common-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { DropdownMenu } from '../DropdownMenu';
import { Messages } from '../Messages/Messages';

import { MessagesMenuProps } from './MessagesMenu.interfaces';

export const MessagesMenu = ({ messages = [] }: MessagesMenuProps) => {
  const { push } = useRouter();

  const { isToggled: isNotificationsMenuOpen, toggle } = useToggle();

  const unreadMessagesCount =
    messages.length > 0 ? messages.map(({ unReadCount }) => Number(unReadCount)).reduce((prev, next) => prev + next) : 0;
  return (
    <DropdownMenu
      additionalClassNames="c-notifications-menu ml-auto spotlight-item--2"
      dropdownId="notifications"
      isDisplayContent={isNotificationsMenuOpen}
      trigger={<Messages count={unreadMessagesCount} />}
      onDisplayContent={messages.length > 0 ? () => toggle() : () => push('/community/messages')}
    >
      <ul>
        {messages
          .filter(({ unReadCount }) => Number(unReadCount) > 0)
          .slice(0, 5)
          .map(({ lastMessage, unReadCount, receiver }) => {
            return (
              <li key={lastMessage?.id}>
                <NextLink href={`/community/messages/${String(receiver?.key)}`}>
                  <a className='c-notifications-menu__button'>
                    <div className="c-notifications-menu__status">
                      <span className="h-hide-visually">Unread</span>
                    </div>

                    <span className="c-notifications-menu__message">
                      {lastMessage?.sender?.displayedName}: {lastMessage?.text}
                    </span>

                    <span className="c-notifications-menu__count">{unReadCount}</span>
                  </a>
                </NextLink>
              </li>
            );
          })}
        <li>
          <NextLink href="/community/messages">
            <a className='c-notifications-menu__button'>See all messages ({unreadMessagesCount})</a>
          </NextLink>
        </li>
      </ul>
    </DropdownMenu>
  );
};
