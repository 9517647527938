import NextLink from 'next/link';

export const AppStoreLinks = () => (
  <div className="flex items-center">
    <NextLink href="/">
      <a className="flex mr-2" href="https://play.google.com/store/apps/details?id=com.netfront.stableground&hl=en" rel="noreferrer" target="_blank">
        <img alt="Google play store" className="h-14" src="/images/google-play-icon.svg" />
      </a>
    </NextLink>
    <NextLink href="/">
      <a className="flex" href="https://apps.apple.com/us/app/stable-ground/id6503231704" rel="noreferrer" target="_blank">
        <img alt="Apple app store" className="h-14" src="/images/app-store-icon.svg" />
      </a>
    </NextLink>
  </div>
);
