import { IUserFlowStepTrack } from '@netfront/ekardo-content-library';
import { format } from 'date-fns';

export const transformISODate = (isoDate: string) => {
  const [year, month, day] = isoDate.split('-');
  return `${day}/${month}/${year}`;
};

export const formatJournalDate = (date: string | Date) => format(new Date(date), 'yyyy-MM-dd');

export const getNewEntryText = (tracks: IUserFlowStepTrack[], date): string => {
  const currentEntry = tracks.find(({ instanceDate }) => instanceDate === format(new Date(date), 'yyyy-MM-dd'));
  return currentEntry ? currentEntry.answers[0]?.text : '';
};
