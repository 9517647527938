import { useEffect, useRef, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';
import { BreadcrumbLink, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { DBConversation, DBUser } from '../../../../interfaces';
import {
  ConversationQueryResult,
  CreateConversationMutation,
  ECommunityRole,
  useCreateConversation,
  useGetConversation,
  useGetUserByKey,
  useMarkWholeConversationRead,
} from '../../../../services';
import { CommentBox, ConversationMessage, ConversationMessageSkeleton, UserAvatar } from '../../../Social';

const ConversationPage = () => {
  const PAGE_TITLE = 'Conversation';

  const {
    asPath,
    query: { userKey },
  } = useRouter();

  const containerRef = useRef<HTMLDivElement>(null);
  const loggedUser = useUser().getUser();
  const { isToggled: isModeratorDialogOpen, toggle: toggleModeratorDialog } = useToggle();

  const [conversationMessages, setConversationMessages] = useState<DBConversation[]>([]);
  const [recipientUser, setRecipientUser] = useState<DBUser>();
  const [message, setMessage] = useState<string>('');

  const handleGetConversationCompleted = (conversations: ConversationQueryResult[]) => {
    if (conversationMessages.length) {
      return;
    }
    setConversationMessages(conversations.map(({ node }) => node));
  };

  const handleGetUserInformationCompleted = (user: DBUser) => {
    if (recipientUser) {
      return;
    }

    setRecipientUser(user);
  };

  const handleCreateConversationCompleted = (conversation: CreateConversationMutation) => {
    const newMessage = conversation.message?.createConversation as unknown as DBConversation;

    setConversationMessages((prevState) => [...prevState, newMessage]);
    setMessage('');

    setTimeout(() => {
      containerRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 200);

    if (isRecipientModerator && !recipientUser?.isOnline) {
      toggleModeratorDialog()
    }
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getConversation, isLoading: isConversationLoading } = useGetConversation({
    onCompleted: handleGetConversationCompleted,
  });

  const { getUserByKey, isLoading: isGetUserByKeyLoading } = useGetUserByKey({
    onCompleted: handleGetUserInformationCompleted,
  });

  const { createConversation, isLoading: isSubmittingConversation } = useCreateConversation({
    onCompleted: handleCreateConversationCompleted,
  });

  const { markWholeConversationReadMutation } = useMarkWholeConversationRead();

  useEffect(() => {
    if (!userKey || !loggedUser) {
      return;
    }

    if (!recipientUser) {
      getUserByKey({
        variables: {
          key: String(userKey),
        },
      });

      return;
    }

    if (conversationMessages.length === 0) {
      getConversation({
        variables: {
          otherUserId: Number(recipientUser.id),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey, recipientUser]);

  useEffect(() => {
    const unreadMessages = conversationMessages.filter(({ isSeenByReceiver, sender }) => !isSeenByReceiver && sender.id !== loggedUser?.id);

    if (unreadMessages.length) {
      markWholeConversationReadMutation({
        variables: {
          senderId: Number(recipientUser?.id),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationMessages]);

  const hasConversation =
    !isConversationLoading && !isGetUserByKeyLoading && loggedUser && recipientUser && conversationMessages.length > 0;

  const isNewConverstation =
    !isConversationLoading && !isGetUserByKeyLoading && loggedUser && recipientUser && conversationMessages.length === 0;


  const isRecipientModerator = recipientUser?.activeCommunities.edges?.some(({ node }) => node?.userConnection?.role === ECommunityRole.Member || node?.userConnection?.role === ECommunityRole.Owner);

  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community">Community</BreadcrumbLink>, key: 'Community' },
          { content: <BreadcrumbLink href="/community/messages">Messages</BreadcrumbLink>, key: 'Messages' },
          { content: <BreadcrumbLink href={asPath}>{recipientUser?.displayedName ?? ''}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{
        seoDescription: `View your conversation history`,
        seoTitle: PAGE_TITLE,
      }}
      pageHeadingContainerClassNames="container-sm"
      title={recipientUser?.displayedName ?? PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-conversation-page__container">
        <NextLink href={`/community/profile/${String(recipientUser?.key)}`}>
          <a className='flex items-center mb-4'>
            <UserAvatar avatar={recipientUser?.profileImage?.presignedUrl} displayName={recipientUser?.displayedName} isModerator={isRecipientModerator} isOnline={recipientUser?.isOnline} size="medium" />
            <h1 className="h3 color-primary mb-0 ml-4">{recipientUser?.displayedName}</h1>
          </a>
        </NextLink>

        {isGetUserByKeyLoading || (isConversationLoading && <ConversationMessageSkeleton />)}
        {hasConversation && (
          <div ref={containerRef} className="c-conversation-page__conversation">
            <div>
              {conversationMessages.map(({ sender, text, isSeenByReceiver }, key) => {
                const isFollowUpMessageFromSameUser = conversationMessages[key - 1]?.sender.id !== sender.id;

                const readStatus =
                  key === conversationMessages.length - 1 ? (sender.id === loggedUser.id ? (isSeenByReceiver ? 'Seen' : 'Sent') : '') : '';

                return (
                  <ConversationMessage
                    key={key}
                    avatarImage={sender.profileImage?.presignedUrl}
                    displayName={sender.displayedName}
                    isSentByUser={sender.id === loggedUser.id}
                    readStatus={readStatus}
                    shouldShowAvatar={isFollowUpMessageFromSameUser}
                    text={text}
                  />
                );
              })}
            </div>
          </div>
        )}

        {isNewConverstation && (
          <div className="c-conversation-page__empty-message">
            <p>
              Send your first message to <strong>{recipientUser.displayedName}</strong>
            </p>
          </div>
        )}

        {!isConversationLoading && !isGetUserByKeyLoading && (
          <form className="c-conversation-page__form">
            <CommentBox
              id="message"
              labelText="Message"
              name="message"
              placeholder="Your message..."
              value={message}
              isLabelHidden
              onChange={setMessage}
            />

            <Button
              isDisabled={Boolean(!message) || isSubmittingConversation}
              text="Send message"
              onClick={() => {
                createConversation({
                  variables: {
                    message: String(message),
                    receiverId: Number(recipientUser?.id),
                  },
                });
              }}
            />
          </form>
        )}
      </div>
      <Dialog
        confirmText='Close'
        isOpen={isModeratorDialogOpen}
        title=""
        onClose={toggleModeratorDialog}
        onConfirm={() => {
          toggleModeratorDialog();
        }}
      >
        <p>Hello,</p>
        <p>We really appreciate the time it takes to make contact. Our moderators are online 7 days a week, from 3am to 7am and 3pm to 7pm. We&#39;ll get back to you as soon as we possibly can.</p>
       
        <p>As a reminder, we don’t provide counselling or crisis support, but here are a range of 24/7 options (phone numbers and online resources) that we recommend you access for immediate help and support.</p>
        <p><strong>Emergency</strong>: If you or someone around you is in immediate danger dial Triple Zero as soon as possible at <a href="tel:+000"><strong className='color-primary'>000</strong></a>.</p>
        <p><strong>Lifeline</strong>: If you are in crisis, feel suicidal or would like support call <a href="tel:+ 13 11 14"><strong className='color-primary'>13 11 14</strong></a> or visit <a className='color-primary' href='https://www.lifeline.org.au/' rel="noreferrer" target='_blank'>www.lifeline.org.au</a> for online chat. </p>
        <p><strong>Suicide Call Back Service</strong>: If you have been affected by suicide and want support or after care, call <a href="tel: +1800 250 015"><strong className='color-primary'>1300 659 467</strong></a> or visit <a className='color-primary' href='https://www.suicidecallbackservice.org.au/' rel="noreferrer" target='_blank'>www.suicidecallbackservice.org.au</a> for online chat.</p>
        <p><strong>National Alcohol and Other Drug Hotline</strong>: If you need help with alcohol or other drug issues call <a href="tel:+1300 659 467"><strong className='color-primary'>1800 250 015</strong></a></p>
        <p className='mb-0'>Kind regards,</p>
        <p>The Stable Ground team</p>
      </Dialog>

    </Page>
  );
};

export { ConversationPage };
